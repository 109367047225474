<div
    class="country-selector"
    nz-button
    nz-dropdown
    [nzDropdownMenu]="menuFlags"
    nzPlacement="bottomRight"
    [ngClass]="{ disabled: disabled }"
>
    <div class="country-selector__flagBtn">
        <div class="iti__flag" [ngClass]="selectedCountry?.flagClass"></div>
        <!-- <div *ngIf="separateDialCode" class="selected-dial-code"> -->
        <!-- <div class="country-selector__selectedDialCode">+{{ selectedCountry.dialCode }}</div> -->
        <div class="iti__arrow"></div>
    </div>
</div>

<nz-dropdown-menu
    #menuFlags="nzDropdownMenu"
    class="country-dropdown"
>
    <ul class="country-dropdown__list iti__country-list" #countryList>
        <li>
            <div class="search-container">
                <input
                    [(ngModel)]="countrySearchText"
                    (keyup)="searchCountry()"
                    (click)="$event.stopPropagation()"
                    [placeholder]="searchCountryPlaceholder"
                    autofocus
                />
            </div>
        </li>
        <!-- <li
            class="iti__country iti__preferred"
            *ngFor="let country of preferredCountriesInDropDown"
            (click)="onCountrySelect(country, null)"
            [id]="country.htmlId + '-preferred'"
        >
            <div class="iti__flag-box">
                <div class="iti__flag" [ngClass]="country.flagClass"></div>
            </div>
            <span class="iti__country-name">{{ country.name }}</span>
            <span class="iti__dial-code">+{{ country.dialCode }}</span>
        </li> -->
        <li
            class="iti__divider"
            *ngIf="preferredCountriesInDropDown?.length"
        ></li>
        <li
            class="iti__country iti__standard"
            *ngFor="let country of filteredCountries"
            (click)="onCountrySelect(country, null)"
            [id]="country.htmlId"
        >
            <div class="iti__flag-box">
                <div class="iti__flag" [ngClass]="country.flagClass"></div>
            </div>
            <span class="iti__country-name">{{ country.name }}</span>
            <span class="iti__dial-code">+{{ country.dialCode }}</span>
        </li>
    </ul>
</nz-dropdown-menu>
