<pane-frame
    class="pwdSettings pwdSettings__pane"
    [includeDefaultHeader]="false"
    [includeDefaultFooter]="false"
>
    <div class="pwdSettings__header" headingCustom>
        <div class="pwdSettings__headerTxt">Change Password</div>
        <nz-alert
            class="pwdSettings__alert pwdSettings__alert--success"
            *ngIf="resultType === 'Success'"
            nzType="success"
            nzMessage="Password changed successfully! Sign in again with the new password."
        ></nz-alert>
        <nz-alert
            class="pwdSettings__alert pwdSettings__alert--error"
            *ngIf="resultType === 'Error'"
            nzType="error"
            [nzMessage]="errorMessage"
        ></nz-alert>
    </div>
    <div mainCustom class="pwdSettings__main">
        <movius-web-pwd-form
            #pwdForm
            class="pwdSettings__pwdForm"
            [style]="'NoBorders'"
            [validation]="'Wide'"
        >
            <div
                buttons
                nz-row
                class="pwdSettings__controls"
                [nzJustify]="'end'"
            >
                <div nz-col>
                    <button
                        type="button"
                        class="pwdSettings__cancel"
                        (click)="clearAllFields()"
                    >
                        Cancel
                    </button>
                </div>
                <div nz-col>
                    <button
                        type="submit"
                        class="pwdSettings__add authButton"
                        [disabled]="
                            !pwdForm.passwordForm.valid || (isSaving$ | async)
                        "
                        (click)="onSave(pwdForm.passwordForm.value)"
                    >
                        <span [ngClass]="{ spinnered: isSaving$ | async }"
                            >Save</span
                        >
                    </button>
                </div>
            </div>
        </movius-web-pwd-form>
    </div>
</pane-frame>
