import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoggerFactory } from '@movius/ts-logger';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AuthService, DataService } from '../../services';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo} from 'rxjs/operators';
const logger = LoggerFactory.getLogger("")
import * as bowser from 'bowser';

@Component({
    selector: 'movius-web-customer-help-details',
    templateUrl: './customer-help-details.component.html',
    styleUrls: ['./customer-help-details.component.scss']
})
export class CustomerHelpDetailsComponent implements OnInit{
    messageTextLength: number = 0;
    remainingText: number = 250;
    public value:string;
    getConnectionErrorValue: any;
    onlineStatus$: Observable<boolean>;
    teamsCallUrl: string;
    customerSupportEmail:string = '';
    customerSupportPhone:string = '';
    customerSupportWebsite:string = 'https://help.moviuscorp.com/help';
    customerSupportWebsiteText:string = 'help.moviuscorp.com';
    isMobileDevice:boolean=false;
    readonly getMailFormat = (
        appName = '',
        appVersion = '',
        osVersion = '',
        browserVersion = '',
        device = '',
        companyOrgId = '',
        multiLineUrl = '',
        virtualNumber = '',
        incidentIds = [''],
    ) => {
        return `
        Please describe the issue you are experiencing here

        Got a screenshot? Add as an attachment.

        ==Do Not Remove This Information==

        App Name : ${appName}
        App Version : ${appVersion}
        OS Version : ${osVersion}

        Browser Version: ${browserVersion}
        Device : ${this.isMobileDevice ? "MLDT Teams Mobile":"MLDT Teams"}
        Company/Org ID : ${companyOrgId}
        MultiLine Desktop URL: ${multiLineUrl}
        Virtual Number : ${virtualNumber}
        Incident IDs:
        \t${incidentIds?.join(',\r\n\t')}`;
    };
    constructor(
        private readonly modalService: NzModalService,
        private readonly _modal: NzModalRef,
        private dataService : DataService,
        private readonly authService: AuthService,

    ) {}

    ngOnInit(): void {
        this.isMobileDevice = ((sessionStorage.getItem('Contex_res')?.toLowerCase() === "ios") || (sessionStorage.getItem('Contex_res')?.toLowerCase() === "android"))
        this.customerSupportEmail = sessionStorage.getItem('__api_customer_support_email__');
        this.customerSupportPhone = sessionStorage.getItem('__api_customer_support_phone__');
        this.teamsCallUrl = `tel:${this.customerSupportPhone}`;
    }

    valueChange(value) {
        this.messageTextLength = value.length;
        this.remainingText = 250 - value.length;
    }

    async submitDetailsELKServer() {
        // this.dataService.setELKStatus(true);
        this._modal.close();
        let postsID = []
        logger.sendPOSTlog("helpSupportClick");
        const postID_err = sessionStorage.getItem("incidentIDS_ERROR")
        const postID_cli = sessionStorage.getItem("incidentIDS_CLIENT")
        if (postID_err !== null || postID_cli !== null) {
            if (postID_err !== null) {
                let postID_errs = postID_err.split("\n")
                for (let i = 0; i < postID_errs.length; i++) {
                    const post_time_and_id = postID_errs[i].split(":::")
                    postsID.push(post_time_and_id[1])
                }
                sessionStorage.removeItem("incidentIDS_ERROR")
            }
            if (postID_cli !== null) {
                const postID_cli_time_id = postID_cli.split(":::")
                postsID.push(postID_cli_time_id[1]);
                sessionStorage.removeItem("incidentIDS_CLIENT")
            }
        }
        const sysInfo = bowser.getParser(window.navigator.userAgent);
        const mail = this.getMailFormat(
            this.isMobileDevice ? "MLDT Teams Mobile":"MLDT Teams",
            localStorage.getItem('App-Version') ? localStorage.getItem('App-Version') : '1.0.0',
            `${sysInfo.getOSName()} ${sysInfo.getOSVersion()}`,
            `${sysInfo.getBrowserName()} ${sysInfo.getBrowserVersion()}`,
            `${sysInfo.getPlatformType()} ${sysInfo.getBrowserName()}`,
            this.authService.apiAuthOrgId,
            `${window['MOVIUS_MS_GRAPH_LOGOUT_URL']}`,
            this.authService.apiIdentity,
            postsID,
        );
        const data = `mailto:${this.customerSupportEmail}?subject=${encodeURI(
            'Technical support'
        )}&body=${encodeURI(mail)}`;
        window.open(data, '_blank');
        // this.submitSuccessConfirmation();
    }

    submitSuccessConfirmation() {
        this.modalService.create({
            nzContent: ConfirmDialogComponent,
            nzComponentParams: {
                infoTitleTxt:
                    'Thank you for your feedback. We will look into this and get back to you soon',
                applyBtnTxtCenter: 'Ok',
            },
            nzClosable: false,
            nzBodyStyle: {
                width: '24rem',
            },
            nzWidth: '24rem',
            nzFooter: null,
        });
        setTimeout(()=>{
            this.dataService.setELKStatus(false);
        },5000)
    }

    public getConnectionError(event :any) {
        this.onlineStatus$ = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(mapTo(true)),
            fromEvent(window, 'offline').pipe(mapTo(false))
        );
        this.onlineStatus$.subscribe(data => {
            logger.debug('General:: Network Status:', data);
            this.getConnectionErrorValue = data;
        })

    }

    closeModal(){
        this._modal.close();
    }

    GetTheme(){
        let theme = localStorage.getItem("Theme")
        return theme == "Dark";
    }
}
