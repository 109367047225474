<pane-frame
    data-cy="sync-contacts"
    [isBodyCentered]="true"
    [includeDefaultBody]="false"
    [includeDefaultHeader]="false"
    [includeDefaultFooter]="false"
>
    <div mainCustom class="syncCnt" [ngSwitch]="state">
        <ng-template ngSwitchCase="not-sync">
            <div class="syncCnt__caption">
                <div class="syncCnt__captionIco"></div>
                <div class="syncCnt__captionTxt">
                    Click <strong>Connect to Office 365 Exchange Server</strong> to access
                    your Exchange Contacts. After you enter your credentials and
                    the sync completes, your contacts will be available here
                    where you can start calling and messaging them.
                </div>
            </div>

            <div class="syncCnt__controls">
                <button
                    data-cy="sync-button"
                    class="syncCnt__syncBtn"
                    (click)="onSync()"
                >
                    Connect to Office 365 Exchange Server
                </button>
                <div
                    data-cy="create-local-button"
                    class="syncCnt__localCnt"
                    (click)="onCreateLocal()"
                >
                    or create <u>local contact</u>
                </div>
            </div>
        </ng-template>

        <movius-web-contact-sync-gain *ngSwitchCase="'syncing'">
        </movius-web-contact-sync-gain>
        <movius-web-contact-sync-fail
            *ngSwitchCase="'error'"
            (tryAgain)="onTryAgain()"
        >
        </movius-web-contact-sync-fail>
    </div>
</pane-frame>
