import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { LoggerFactory } from '@movius/ts-logger';
import { Store } from '@ngrx/store';
import { SipService } from '@scalio/sip';
import { getDate, parseISO } from 'date-fns';
import { BehaviorSubject, Observable, Subject, interval, timer } from 'rxjs';
import { delay, filter, map, take, takeUntil } from 'rxjs/operators';
import { DateTimeService, getFeatureEnabled } from '../../../shared';
import { ChatSessionView, PeerChatMessage } from '../../models';
import {
    PeerChatMessageView,
    selectMessageSearchText,
    selectPeerMessagesByKey,
    selectPeersMessages,
} from '../../ngrx';
import {
    ChatWorkspaceService,
    ScrollToBottomFlag,
    MessagingService,
} from '../../services';
import { PeerChatMessageComponentView } from '../chat-item/chat-item.component';
import { ChatItemObserver } from './chat-item-observer';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { combineLatest } from 'rxjs';
const logger = LoggerFactory.getLogger('');

const checkIfDateBefore = (cur: PeerChatMessage, prev: PeerChatMessage) => {
    if (!cur && !prev) {
        return false;
    }
    if (!!cur && !prev) {
        return true;
    }
    return (
        getDate(parseISO(cur?.sentTime)) !== getDate(parseISO(prev?.sentTime))
    );
};

const formatDate = (dateTimeService: DateTimeService, msg: PeerChatMessage) => {
    if (!msg?.sentTime) return null;
    return dateTimeService.formatHistoryDate(msg?.sentTime);
};

const BATCH_SIZE = 20;
const BATCH_OFFSET = 5;

const getBatchIndex = (n: number) => Math.floor(n / BATCH_SIZE);

export interface FirstNotReadMemoized {
    msgId: string;
    totalCount: number;
}

const mapToComponentView = (
    firstNotRead: FirstNotReadMemoized | null,
    dateTimeService: DateTimeService,
    pervMsg: PeerChatMessageView,
    msg: PeerChatMessageView,
    i: number,
    totalUnreadCount: number
): PeerChatMessageComponentView => {
    const batchIndex1 = getBatchIndex(i + BATCH_OFFSET);
    const batchIndex2 = getBatchIndex(i + 1 + BATCH_OFFSET);
    const isFirstNotRead = (!pervMsg || pervMsg.isRead) && !msg.isRead;
    return {
        ...msg,
        isFirstNotRead:
            firstNotRead && totalUnreadCount > 0
                ? firstNotRead.msgId === msg.id
                    ? firstNotRead.totalCount
                    : false
                : isFirstNotRead && totalUnreadCount > 0
                ? totalUnreadCount
                : false,
        isDateBefore: checkIfDateBefore(msg, pervMsg),
        dateFormatted: formatDate(dateTimeService, msg),
        batchIndex: getBatchIndex(i),
        nextBatchSignal: batchIndex1 !== batchIndex2,
    };
};

const checkNotifyItemVisible = (session: ChatSessionView) => (
    msg: PeerChatMessageComponentView
) => {
    return !msg.isRead || msg.id === session.loadNextPageMessageId;
};

@Component({
    selector: 'movius-web-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnDestroy, OnInit, OnChanges {
    appEmbededStatus: string;
    private firstNotReadMemoized: FirstNotReadMemoized = null;
    _session: ChatSessionView;
    emitMessageDisplayed = false;
    contentIdValue: any;
    matchedMsgCount: number = 0;
    matchedMsgElements: any = [];
    public mmelemtnstest: any = [];
    loadedChat: boolean = false;
    scrollHeight: number = 0;
    msgHavingInbtwText: any = [];
    messagesData: PeerChatMessageComponentView[];
    messageLength: number = 0;
    msgSearchContent: string = '';
    msgLength = 40;
    initialScroll: boolean = false;
    PreventScroll: boolean = false;
    private readonly nextset$ = new BehaviorSubject<number>(this.msgLength);

    scrolled: boolean = false;
    private readonly activeBatchIndex$ = new BehaviorSubject(0);
    private readonly allMessages$ = new BehaviorSubject<
        PeerChatMessageComponentView[]
    >([]);
    readonly renderedMessages$: Observable<PeerChatMessageComponentView[]>;
    private readonly destroy$ = new Subject();
    private chatItemObserver = new ChatItemObserver((msg) =>
        this.onMessageItemDisplayed(msg)
    );
    @ViewChild('messagesContainer') private messagesContainer: ElementRef<
        HTMLElement
    >;
    @ViewChild('messagesViewPort')
    private messagesViewPortContainer: ElementRef<HTMLElement>;
    // @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    @ViewChildren('item', { read: ElementRef }) private itemElements: QueryList<
        ElementRef<HTMLElement>
    >;

    // @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    ngOnChanges() {
        this.chatItemObserver = new ChatItemObserver((msg) => {
            this.onMessageItemDisplayed(msg);
        });
    }

    @Input() set session(val: ChatSessionView) {
        // subscribing data from selector when the messages array is shown empty in @Input when optin request is sent
        let newThread = false;
        if (val.messages.length === 0) {
            // const peerMessages$ = this.store
            //     .select(selectPeersMessages(this.sipService.getUserUri))
            //     .pipe(
            //         map((m) => m.filter((f) => f.messages.length > 0)),
            //     );

            this.messagingService.peerMessages$
                .pipe(takeUntil(this.destroy$))
                .subscribe((peers) => {
                    let filterPeerIdData: any = peers.filter((res) => {
                        return res.threadId === this.router.url.split('/')[3];
                    });
                    if (filterPeerIdData.length > 0) {
                        val.messages = filterPeerIdData[0].messages;
                    }
                    val.threadId = this.router.url.split('/')[3];
                });
        }
        if (this._session !== val) {
            if (this._session?.threadId !== val.threadId) {
                this.firstNotReadMemoized = null;
                this.emitMessageDisplayed = false;
                newThread = true;
                this.initialScroll = false;
            }

            if (
                this._session?.threadId === val.threadId &&
                this._session.messages.length == 1
            ) {
                this.initialScroll = false;
            }
            this._session = val;
            const reversed = (val?.messages
                ? (val.messages as PeerChatMessageView[])
                : []
            )
                .slice()
                .reverse(); //

            const totalUnreadCount = reversed.filter((f) => !f.isRead).length;

            let allMessages = reversed.map((item, i) =>
                mapToComponentView(
                    this.firstNotReadMemoized,
                    this.dateTimeService,
                    reversed[i - 1],
                    item,
                    i,
                    totalUnreadCount
                )
            );
            const firstNotRead = allMessages.find(
                (f) => f.isFirstNotRead !== false
            );
            if (firstNotRead) {
                this.firstNotReadMemoized = {
                    msgId: firstNotRead.id,
                    totalCount: firstNotRead.isFirstNotRead as number,
                };
            }
            logger.debug(
                'General:: Total No of Messages for Thread::' +
                    this._session?.threadId +
                    ':',
                allMessages.length
            );
            combineLatest([
                this.messagingService.voiceMailFilter$,
                this.messagingService.voiceMailFilterOnSearch$
              ]).subscribe(([voiceMailFilter, voiceMailFilterOnSearch]) => {
                if (voiceMailFilter || voiceMailFilterOnSearch) {
                  // At least one of the filters is true
                  allMessages = allMessages.filter(message => message.messageType == "voicemail")
                } else {
                  // Both filters are false
                  allMessages = allMessages.filter(message => message.messageType != "voicemail");
                }
                allMessages = allMessages.map((item, i) =>
                    mapToComponentView(
                        this.firstNotReadMemoized,
                        this.dateTimeService,
                        allMessages[i - 1],
                        item,
                        i,
                        totalUnreadCount
                    ))
              });
            this.messagesData = allMessages;
            this.messageLength = allMessages.length;
            if (allMessages.length > this.msgLength) {
                if (newThread) {
                    this.nextset$.next(this.msgLength);
                } else {
                    this.nextset$.next(this.nextset$.getValue());
                }
            } else {
                this.allMessages$.next(allMessages);
            }
        }
    }
    @Output() messageDisplayed = new EventEmitter<PeerChatMessage>();
    @Output() removeMessage = new EventEmitter<PeerChatMessage>();
    @Output() forwardMessage = new EventEmitter<PeerChatMessage>();
    @Output() copyMessage = new EventEmitter<PeerChatMessage>();
    @Input() loadedPictureMessage_chat_workspace: string[];
    @Input() cnt_id: Observable<number>;
    @Output() loadedImages_chat_workspace = new EventEmitter();

    @Input()selectedDeleteMessages: any = [];
    @Input()isDeleteactive: boolean = false;
    @Input()isDeleteEnabled: boolean = false;


    @Output() onSlectionChange = new EventEmitter<any>();



    isLogingViaTeams: string;
    timeoutId;
    isMobileDevice: Boolean = false;
    get session() {
        return this._session;
    }

    constructor(
        private readonly dateTimeService: DateTimeService,
        public chatWorkspaceService: ChatWorkspaceService,
        private readonly messagingService: MessagingService,
        private readonly store: Store,
        private router: Router,
        private sipService: SipService
    ) {

        this.isMobileDevice =
            sessionStorage.getItem('Contex_res')?.toLowerCase() === 'ios' ||
            sessionStorage.getItem('Contex_res')?.toLowerCase() === 'android'
                ? true
                : false;
        this.isLogingViaTeams = sessionStorage.getItem('isLogingViaTeams');
        this.appEmbededStatus = getFeatureEnabled();
        store
            .select(selectMessageSearchText)
            .pipe(takeUntil(this.destroy$))
            .subscribe((searchText) => {
                this.msgSearchContent = searchText;
            });
        this.allMessages$
            .pipe(
                takeUntil(this.destroy$),
                delay(0),
                filter(
                    () =>
                        this._session.status?.kind === 'StateStatusLoaded' ||
                        this._session.status?.kind === 'StateStatusInitial'
                )
            )
            .subscribe((items) => {
                const checkNotify = checkNotifyItemVisible(this._session);
                const hashUnreadMessages = items.some((f) => !f.isRead);
                if (!this.emitMessageDisplayed) {
                    setTimeout(() => {
                        this.emitMessageDisplayed = true;
                        if (hashUnreadMessages) {
                            this.scrollToUnread();
                        } else {
                                this.scrollToBottom('none');
                        }
                        setTimeout(() => {
                            this.initialScroll = true
                        }, 2500);

                        this.chatItemObserver.update(
                            items,
                            this.itemElements.toArray(),
                            checkNotify
                        );
                    }, 0);
                } else {
                    this.chatItemObserver.update(
                        items,
                        this.itemElements.toArray(),
                        checkNotify
                    );
                    // if(!this.PreventScroll)
                    if(!this.PreventScroll)
                    {
                        if (hashUnreadMessages) {
                            this.scrollToUnread();
                        }else{
                            this.scrollToBottom('none');
                        }

                    }
                    setTimeout(() => {
                        this.initialScroll = true
                    }, 2500);
                    // clearTimeout(this.timeoutId);
                    // this.timeoutId = setTimeout(() => {
                    //     this.PreventScroll = false
                    // }, 2000);
                }
            });

        chatWorkspaceService.scrollToBottom
            .pipe(takeUntil(this.destroy$))
            .subscribe((flag) => {
                logger.debug('movius-web-chat:scrollToBottom', flag);
                this.scrollToBottom(flag);
            });
    }

    loadNextConversationFromStorage(nextCount) {
        if (nextCount == 0) {
            if (this.messagesData.length > this.msgLength) {
                return this.messagesData.slice(
                    this.messagesData.length - this.msgLength,
                    this.messagesData.length
                );
            } else {
                return this.messagesData;
            }
        } else if (nextCount > 0) {
            if (this.messagesData.length > nextCount) {
                return this.messagesData.slice(
                    this.messagesData.length - nextCount,
                    this.messagesData.length
                );
            } else {
                return this.messagesData;
            }
        }
    }

    scrollOffsetOnNewMessageLoad() {
        if (scroll && this.scrollHeight != 0 && this.initialScroll) {
            setTimeout(() => {
                const scroll = document.getElementsByClassName('chatscroll')[0];
                if (scroll.scrollHeight != this.scrollHeight)
                    scroll.scrollTo(0, scroll.scrollHeight - this.scrollHeight);
            }, 100);
        }
    }

    GetTheme() {
        let theme = localStorage.getItem('Theme');
        return theme ? 'Dark' : null;
    }
    ngOnInit() {
        // this.appEmbededStatus = getFeatureEnabled();
        this.allMessages$
            .pipe(takeUntil(this.destroy$), delay(0))
            .subscribe((items) => {
                setTimeout(() => {
                    this.messagingService.changeName(0);
                    this.messagingService.MessageLazyLoaded.pipe(
                        takeUntil(this.destroy$)
                    ).subscribe((res) => {
                        this.loadedChat = res;
                        if(res){
                            clearTimeout(this.timeoutId);
                            this.PreventScroll = true
                            this.timeoutId = setTimeout(() => {
                                this.PreventScroll = false
                            }, 2000);
                        }
                    });
                    this.messagingService.name
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((data) => {
                            this.matchedMsgCount = data;
                        });
                    if (
                        this.msgSearchContent != null &&
                        this.msgSearchContent.length >= 2
                    ) {
                        this.msgHavingInbtwText = [];
                        this.mmelemtnstest = [];
                        const hashUnreadMessages = items.some((f) =>
                            f.content.includes(this.msgSearchContent)
                        );
                        items.forEach((ext) => {
                            if (ext.content.includes(this.msgSearchContent)) {
                                this.contentIdValue = ext.content;
                                this.msgHavingInbtwText.push(
                                    this.contentIdValue
                                );
                            }
                        });

                        if (hashUnreadMessages) {
                            let unreadMessagesElement = document.querySelectorAll(
                                `[id^=` +
                                    "'" +
                                    this.msgSearchContent +
                                    "'" +
                                    `]`
                            );

                            if (unreadMessagesElement.length > 0) {
                                this.mmelemtnstest.push(unreadMessagesElement);
                            }

                            if (this.msgHavingInbtwText != null) {
                                this.msgHavingInbtwText.forEach((btwtxt) => {
                                    unreadMessagesElement = document.querySelectorAll(
                                        `[id^=` + "'" + btwtxt + "'" + `]`
                                    );
                                    this.mmelemtnstest.push(
                                        unreadMessagesElement
                                    );
                                });
                            }

                            if (this.mmelemtnstest.length == 1) {
                                this.matchedMsgCount = this.mmelemtnstest[0].length;
                            } else {
                                this.matchedMsgCount = this.mmelemtnstest.length;
                            }

                            this.messagingService.changeName(
                                this.matchedMsgCount
                            );
                            sessionStorage.setItem(
                                'matchedMsgCounttest',
                                this.matchedMsgCount.toString()
                            );
                            this.mmelemtnstest.push(unreadMessagesElement);

                            this.mmelemtnstest?.forEach((elmt) => {
                                elmt[0]?.scrollIntoView();
                            });
                        }
                    }
                }, 0);
            });

        this.nextset$.subscribe((res) => {
            if (res) {
                this.allMessages$.next(
                    this.loadNextConversationFromStorage(res)
                );
            }
        });
    }

    ngOnDestroy(): void {
        this.chatItemObserver.dispose();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private checkOffset(nativeElement: HTMLElement) {
        return true;
    }
    isMessaging() {
        return this.appEmbededStatus == 'messaging'
            ? 'chat__teams_viewport'
            : 'chat__viewport';
    }

    scrollToBottom(flag: ScrollToBottomFlag) {
        const nativeElement = this.messagesContainer?.nativeElement;

        if (nativeElement) {
            if (
                flag === 'ignoreWhenOffset' &&
                !this.checkOffset(nativeElement)
            ) {
                return;
            }
            if (flag === 'ignoreWhenOffset') {
                this.onMessageItemDisplayed(this.session.messages[0]['id']);
            }
            nativeElement.scrollIntoView({
                block: 'end',
            });
        }
    }

    private scrollToUnread() {
        const unreadMessagesElement = document.getElementById(
            'unread_messages_element'
        );
            unreadMessagesElement?.scrollIntoView({  block: 'nearest', inline: 'nearest' })
    }

    trackByFun(_, item: PeerChatMessageView) {
        return item.id;
    }

    onBecomeVisible(msg: PeerChatMessage) {
        if (msg && msg.id !== null) {
            this.messageDisplayed.emit(msg);
        }
    }

    private onMessageItemDisplayed = (msgId) => {
        //logger.debug('onMessageItemDisplayed', msgId);

        const msg = this.allMessages$.value.find((f) => f.id === msgId);
        if (msg?.nextBatchSignal) {
            this.activeBatchIndex$.next(this.activeBatchIndex$.value + 1);
        }
        if (msg && msg.id !== null) {
            this.messageDisplayed.emit(msg);
        }
    };

    loadedImages_chat(event) {
        this.loadedImages_chat_workspace.emit(event);
    }

    goToPrev() {
        let curVal = this.matchedMsgCount - 1;
    }

    goToNext() {
        let curVal = this.matchedMsgCount + 1;
    }

    loadPreviousMessages() {
        if (this.initialScroll && this.chatWorkspaceService.pictureMessageLoad) {
            const scroll = document.getElementsByClassName('chatscroll')[0];
            this.PreventScroll = true
            if (this.messagesData.length <= this.nextset$.getValue()) {
                this.messagingService.MessageLazyLoaded.next(true);
                this.scrollHeight = scroll.scrollHeight;
                try {
                    const threadid = this.session.peer.id?.includes('whatsapp:')
                        ? this.session.threadId
                        : this.session.peer.id;
                    let seq = null;
                    if (this.session.messages.length > 0) {
                        let length = this.session.messages.length;
                        if (this.session.messages[length - 1].state['seq']) {
                            seq = this.session.messages[length - 1].state[
                                'seq'
                            ];
                        } else {
                            this.store
                                .select(selectPeerMessagesByKey(threadid))
                                .pipe(take(1))
                                .subscribe((res) => {
                                    if (res.status.latestLoadedSeq) {
                                        seq = res.status.latestLoadedSeq.seq;
                                    } else {
                                        seq = res.seq;
                                    }
                                    this.messagingService.loadPreviousPeerHistory(
                                        threadid,
                                        seq,
                                        ''
                                    );
                                });
                        }
                    } else {
                        seq = this.session.status.latestLoadedSeq?.seq;
                    }
                    if (seq)
                        this.messagingService.loadPreviousPeerHistory(
                            threadid,
                            seq,
                            ''
                        );
                    else this.messagingService.MessageLazyLoaded.next(false);
                } catch (e) {
                    logger.debug('error in load new messages. ' + e);
                    this.messagingService.MessageLazyLoaded.next(false);
                }
            } else {
                this.loadedChat = true;
                this.scrollHeight = scroll.scrollHeight;
                this.nextset$.next(this.nextset$.getValue() + this.msgLength);
                this.scrollOffsetOnNewMessageLoad();
            }
        }
    }

    OndeleteThreadClick(data,type){
        if(data.messageType != "voicemail")
            this.onSlectionChange.next({data:data,type:type})
    }



    onLongPress(event,msg){
        if(this.isDeleteEnabled && this.isMobileDevice){
            this.OndeleteThreadClick(msg,'deleteMenu')

        }
    }

    handleMobileDelete(event,msg){
        if(this.isDeleteactive && this.isMobileDevice){
            event.stopPropagation();
            if(this.isDeleteEnabled)
                this.OndeleteThreadClick(msg,'deleteMenu')

        }
    }
}
