import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SipService } from '@scalio/sip';
import { uniqBy } from 'lodash/fp';
import { NzModalService } from 'ng-zorro-antd/modal';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
    deleteContact,
    selectContactGhosts,
    sendCustomerOptInRequest,
    startAddToExistentContact,
    UserContactGhost
} from '../../../feature-contacts';
import { getFeatureEnabled, cleanPhoneNumber, getPeerNumberWOSpecialChars, getMultiLineNumber, getLineWechatRegex } from '../../../shared';
import { selectHash, selectMessagesContactGhosts, selectPeersMessages } from '../../ngrx';
import { OptInWhatsappTemplateComponent } from '../optIn-whatsapp-template/optIn-whatsapp-template.component';
import { MessagingService } from '../../services/messaging.service';

export interface DetailsWorkspaceView {
    ghost: UserContactGhost;
}

@Component({
    selector: 'movius-web-details-workspace',
    templateUrl: './details-workspace.component.html',
    styleUrls: ['./details-workspace.component.scss'],
})
export class DetailsWorkspaceComponent implements OnInit, AfterViewChecked, AfterViewInit {

    view$: Observable<DetailsWorkspaceView>;
    peers$: Observable<UserContactGhost[]>;
    appEmbededStatus: string;
    hashedRecords: any  = [];
    getActiveChatId: any
    messagingThreadList:any = [];
    peerMessages: any;
    hasLineThread: boolean = false;
    hasWeChatThread: boolean = false;
    lineThreads: any=[]
    wechatThreads: any=[]

    constructor(
        private readonly router: Router,
        private activatedRouter: ActivatedRoute,
        private readonly store: Store,
        private sipService: SipService,
        private readonly modalService: NzModalService,
        private readonly messagingService:MessagingService
    ) {
        this.appEmbededStatus = getFeatureEnabled();
        // const peerMessages$ = store
        // .select(selectPeersMessages(sipService.getUserUri))
        // .pipe(
        //     map((m) => m.filter((f) => f.messages.length > 0))
        // );
        this.messagingService.peerMessages$.subscribe(peers => {
        if (peers.length > 0) {
            this.peerMessages = peers;
        }
    });
        this.store.select(selectHash).subscribe((res => {
            this.hashedRecords = res;
            this.onContactInformation();
        }))
    }
    ngAfterViewInit():void{
        this.appEmbededStatus = getFeatureEnabled();
        this.store.select(selectHash).subscribe((res => {
            this.hashedRecords = res;
            this.onContactInformation();
        }))
    }
    ngAfterViewChecked(): void {

    }
    async getLineWechatThreads(peerNumber, phoneType:string=''){
        const peerMessages$ = this.store.pipe(
            select(selectPeersMessages(this.sipService.getUserUri)),
            take(1)
        )
        const regex = getLineWechatRegex(peerNumber)
        let allWechatOrLineThreads;
        let onlyThread;
        peerMessages$.subscribe(messages => {
            allWechatOrLineThreads = messages
                ?.filter(message => message.peerId?.match(regex))
                ?.map(({messages, ...rest}) => rest);
            onlyThread = messages
            ?.filter(message => message?.peerId?.includes(peerNumber)).length==1
        });

        allWechatOrLineThreads?.map(thread=>{
            let num = thread.participants.find(participant=>regex.test(participant))
            num = num.replace("whatsapp:",'')
            if (num.startsWith('100')) {
                if(!this.wechatThreads.some(thread=>(thread.multiLineNumber === getMultiLineNumber(num))))
                    this.wechatThreads.push({ lineOrWechat:true,messsageChannelType:'WeChat', lineOrWechatNum:num, lineOrWechatId:num.replace('whatsapp:', ''), multiLineNumber:getMultiLineNumber(num),...(phoneType!=''?{phoneType}:{})  })
            }
            if (num.startsWith('101')) {
                if(!this.lineThreads.some(thread=>thread.multiLineNumber === getMultiLineNumber(num)))
                    this.lineThreads.push({ lineOrWechat:true,messsageChannelType:'Line', lineOrWechatNum:num, lineOrWechatId:num.replace('whatsapp:', ''), multiLineNumber:getMultiLineNumber(num),...(phoneType!=''?{phoneType}:{})  });
            }

        })
        if(this.wechatThreads.length>0) {

            this.hasWeChatThread = true}
        if(this.lineThreads.length>0) {

            this.hasLineThread = true}
    }
    checkLineOrWechat(peerNumber, isGroup:boolean=false, phoneType:string=''){
        const peerMessages$ = this.store.pipe(
            select(selectPeersMessages(this.sipService.getUserUri)),
            take(1)
        )
        const regex = getLineWechatRegex(peerNumber)
        let allWechatOrLineThreads;
        let onlyThread;
        peerMessages$.subscribe(messages => {
            allWechatOrLineThreads = messages
                ?.filter(message => regex.test(message.peerId))
                ?.map(message => message.peerId);
            onlyThread = messages
            ?.filter(message => message?.peerId?.includes(peerNumber)).length==1

        });
        if(allWechatOrLineThreads.length>0 && !isGroup){
            peerNumber = allWechatOrLineThreads[0]
        }

        let hasLineChat = allWechatOrLineThreads?.filter((f)=> f.includes('whatsapp:101'));
        let hasWeChat = allWechatOrLineThreads?.filter((f)=> f.includes('whatsapp:100'));
        if(hasLineChat.length >= 1){
            this.hasLineThread = true;
        }
        if(hasWeChat.length >= 1){
            this.hasWeChatThread = true;
        }

        if (peerNumber && peerNumber?.includes('whatsapp')) {
            let num = peerNumber.replace('whatsapp:', '');
            if (num.startsWith('100')) return{ lineOrWechat:true,messsageChannelType:'WeChat', lineOrWechatNum:peerNumber, lineOrWechatId:peerNumber.replace('whatsapp:', ''), multiLineNumber:getMultiLineNumber(peerNumber), onlyThread,...(phoneType!=''?{phoneType}:{}) };
            if (num.startsWith('101')) return{ lineOrWechat:true,messsageChannelType:'Line', lineOrWechatNum:peerNumber, lineOrWechatId:peerNumber.replace('whatsapp:', ''), multiLineNumber:getMultiLineNumber(peerNumber), onlyThread,...(phoneType!=''?{phoneType}:{}) };
        }
    }

    async onContactInformation(){
        this.peers$ = this.store.select(selectContactGhosts(this.sipService.getUserUri));
        const id$ = this.activatedRouter.params.pipe(map(({ id }) => id));
        this.activatedRouter.params.subscribe((val) => this.getActiveChatId = val.id)
        let getCurrentChatId = this.getActiveChatId;
        let getCurrentChatIdWA = this.getActiveChatId;
        let groupId
        this.activatedRouter.queryParamMap.subscribe((value)=>{
            groupId=value['params']?.group
        })

        let peerMessagedObj = this.hashedRecords[this.getActiveChatId]
        // let allGroupObjPeerIds =  Object.keys(this.hashedRecords)
        // .filter(record => this.hashedRecords[record].isGroup)
        // .map(record => this.hashedRecords[record].peerId)
        // .filter(peerId => peerId !== null);

        if (!peerMessagedObj){
            peerMessagedObj = this.hashedRecords[groupId]
            if(!peerMessagedObj){
                const peerMessages$ = this.store.pipe(
                    select(selectPeersMessages(this.sipService.getUserUri)),
                    take(1)
                )
                let regex = getLineWechatRegex(getCurrentChatId)
                let allWechatOrLineThreads;
                peerMessages$.subscribe(messages => {
                    allWechatOrLineThreads = messages
                        .filter(message => regex.test(message.peerId))
                        .map(message => message.threadId);
                });
                if(allWechatOrLineThreads.length>0)
                    peerMessagedObj = this.hashedRecords[allWechatOrLineThreads[0]]
                if(peerMessagedObj?.isGroup)
                    groupId=peerMessagedObj.threadId
            }
        }
        if (peerMessagedObj?.messageChannelType != 'normalMsg') {
            if (peerMessagedObj?.participants) {
                if(groupId){
                    await this.getLineWechatThreads(peerMessagedObj?.participants.find(participant=>participant.includes(this.getActiveChatId)))
                    getCurrentChatIdWA = peerMessagedObj?.participants.find(participant=>participant.includes(this.getActiveChatId)) ? peerMessagedObj?.participants.find(participant=>participant.includes(this.getActiveChatId)) : peerMessagedObj?.peerId
                }
                else{
                    await this.getLineWechatThreads(peerMessagedObj?.participants[0])
                    getCurrentChatIdWA = peerMessagedObj?.participants[0] ? peerMessagedObj?.participants[0] : peerMessagedObj?.peerId
                }
            }
        } else {
            await this.getLineWechatThreads(this.getActiveChatId)
        }
        const userContactGhosts$ = id$.pipe(
            switchMap((id) =>
                this.store
                    .select(selectMessagesContactGhosts(this.sipService.getUserUri))
                    .pipe(map((ghosts) => ghosts.find((f) => {
                        if (f.id === getCurrentChatId) {
                            return f.id === getCurrentChatId
                        } else {
                            // || (groupId ?['Line', 'Wechat'].includes(peerMessagedObj.messageChannelType) : false)
                            if(f.id === getCurrentChatIdWA.replace("whatsapp:", ''))
                                return true
                            else
                                return f.id ===  (this.hasLineThread||this.hasWeChatThread  ? getMultiLineNumber(getCurrentChatIdWA) :getCurrentChatIdWA)
                            // return f.id ===  getCurrentChatIdWA
                        }
                    })))
            ),
            filter((f) => !!f)
        );
        this.view$ = combineLatest([
            userContactGhosts$,
        ]).pipe(
            map(([userContactGhost]) => {
                if(userContactGhost.contact){
                    this.wechatThreads = []
                    this.lineThreads = []
                    userContactGhost.contact?.phones?.map(phone=>{
                        this.getLineWechatThreads(phone.phone, phone.type)
                    })
                }
                return {
                    ghost: userContactGhost
                };
            })
        );
    }

    ngOnInit(): void {}

    onCreateContact(peerId: string) {
        if (this.appEmbededStatus === 'messaging') {
            this.router.navigate(['messaging/new', getMultiLineNumber(peerId)], {
            });
        } else {
            this.router.navigate(['contacts', 'new', getMultiLineNumber(peerId)]);
        }
    }

    onAddToContact(peerId: string) {
        this.store.dispatch(startAddToExistentContact({ mlNumber: getMultiLineNumber(peerId) }));
    }

    onDeleteContact({id, peerId}) {
        this.store.dispatch(deleteContact({ id, peerId }));
    }

    onWhatsAppMessage(session) {
        this.getOptInParticipants(session.peerId, session.messageChannelType==='whatsapp' ? true : false);
    }

    loadPeerMessagesList(peerId) {
        // this.peerMessages.filter((peers) => {
        //     if (peers.messageChannelType == 'whatsapp') {
        //         peers.participants.filter((peer) => {
        //             if (peer == `whatsapp:${cleanPhoneNumber(peerId)}`) {
        //                 this.messagingThreadList.push(peers);
        //             }
        //         })
        //     }
        // })
        this.peerMessages?.filter((peers) => {
            if (peers.messageChannelType != 'normalMsg') {
                peers.participants.filter((peer) => {
                    if (peer == `whatsapp:${cleanPhoneNumber(peerId)}`) {
                        this.messagingThreadList.push(peers);
                    }
                })
            }
        })
    }

    getOptInParticipants(peerId: string, showActionBtns:boolean) {
        this.loadPeerMessagesList(peerId)
        if (this.messagingThreadList.length > 0) {
            this.messagingThreadList = [];
            this.modalService.create({
                nzContent: OptInWhatsappTemplateComponent,
                nzComponentParams: {
                    headerTitle: 'History',
                    actionBtnTitle: 'New Chat',
                    waPeerId: peerId,
                    showActionBtns,
                },
                nzStyle: {
                    margin: '20px auto',
                },
                nzMask: true,
                nzFooter: null,
                nzClosable: false,
                nzKeyboard: false,
                nzMaskClosable: false,
                nzCentered: true,
            })
            .afterClose.subscribe(res=>{
                    this.disabelVoiceMailFilter()
            });
        } else {
            const peerIdVal = `whatsapp:${cleanPhoneNumber(peerId)}`;
            this.store.dispatch(sendCustomerOptInRequest({ peerId: peerIdVal }));
            this.disabelVoiceMailFilter()

        }
    }

    onWeChatMessage(session: any) {
        this.modalService.create({
            nzContent: OptInWhatsappTemplateComponent,
            nzComponentParams: {
                headerTitle: 'History',
                actionBtnTitle: 'New Chat',
                waPeerId: session.peerId,
                showActionBtns: false,
            },
            nzStyle: {
                margin: '20px auto',
            },
            nzMask: true,
            nzFooter: null,
            nzClosable: false,
            nzKeyboard: false,
            nzMaskClosable: false,
            nzCentered: true,
        })
            .afterClose.pipe(
        );
    }

    onLineMessage(session: any) {
        this.modalService.create({
            nzContent: OptInWhatsappTemplateComponent,
            nzComponentParams: {
                headerTitle: 'History',
                actionBtnTitle: 'New Chat',
                waPeerId: session.peerId,
                showActionBtns: false,
            },
            nzStyle: {
                margin: '20px auto',
            },
            nzMask: true,
            nzFooter: null,
            nzClosable: false,
            nzKeyboard: false,
            nzMaskClosable: false,
            nzCentered: true,
        })
            .afterClose.pipe(
        );
    }

    disabelVoiceMailFilter(){
        this.messagingService.voiceMailFilter$.next(false)
    }

}
