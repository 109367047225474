<div
    [ngClass]="getMessageWidth()"
    [class.messages--topSpaced]="getConnectionErrorValue === true"
    *ngIf="view$ | async as view">
    <movius-web-general-failure
        (passConnectionError)="getConnectionError($event)"
    ></movius-web-general-failure>

    <movius-web-empty-messages
        *ngIf="view.showEmptyListPlaceholder; else messagingContent">
    </movius-web-empty-messages>

    <ng-template #messagingContent>
        <!-- <div
            class="messages__splitter messages__splitter--first messages__general"
        > -->
<!--        [ngClass]="classesForList" [ngStyle]="stylesForList">-->
<!--         <div><h1>{{isHideChatList}}</h1></div>-->
<div [ngClass]="getMessageStyle()"  >
        <div #resizable class="resize-message overflow-visible" [ngClass] = "isHidden()" >
            <div *ngIf="!isMobileDevice" class="resize-handle" (mousedown)="initResize($event)">
                <ng-container *ngIf="GetTheme() == 'Dark'">
                    <svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="13" height="25" fill="#161616" stroke="#5D5D5D"/>
                        <circle cx="7" cy="9" r="0.5" fill="#A9A9A9" stroke="#5D5D5D"/>
                        <circle cx="7" cy="13" r="0.5" fill="#A9A9A9" stroke="#5D5D5D"/>
                        <circle cx="7" cy="17" r="0.5" fill="#A9A9A9" stroke="#5D5D5D"/>
                    </svg>
                </ng-container>
                <ng-container *ngIf="GetTheme() != 'Dark'">
                    <svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="13" height="25" fill="white" stroke="#A9A9A9"/>
                        <circle cx="7" cy="9" r="1" fill="#A9A9A9"/>
                        <circle cx="7" cy="13" r="1" fill="#A9A9A9"/>
                        <circle cx="7" cy="17" r="1" fill="#A9A9A9"/>
                        </svg>
                </ng-container>
            </div>
            <div *ngIf="appEmbededStatus == 'messaging'">
                <movius-web-header [composeMessageType]="composeMessageType" [popOverContent]="popOverContent"
                    [teamsLocationEnabled]="teamsLocationEnabled" [is911Message]="is911Message" [isLocationEnabled]="isLocationEnabled"
                    [showHeader]="showHeader" [isCollapsed]="isCollapsed" [userInfo]="userInfo" [view]="feature"
                    (MuteStatus)="changeMuteStatus($event)" [MuteinboundCallAndMsgSound]="MuteinboundCallAndMsgSound">
                </movius-web-header>
            </div>
            <div class="messages___body" [ngClass]="{'messages___mldt-body':appEmbededStatus !== 'messaging'}">
                <pane-frame
                class="messages__pane"
                [isChatHeight] = "this.chatHeight"
                [includeDefaultHeader]="false"
                [includeDefaultFooter]="false"
                (isBackBtnClicked)="backBtnClicked($event)"
                scrollClass = "threadscroll"
                >
                <div headingCustom class="messages__heading"
                    *ngIf="appEmbededStatus !== 'messaging'">
                    <div class="messages__caption" [class.hidden]="searchBarActive">
                        Messages
                    </div>
                    <div class="messages__headingRight" [class.active]="searchBarActive" >
                            <!-- <div  *ngIf="!searchBarActive" class="mli_refresh" (click)="forceRefreshMLIData()" data-tooltip="Sync MultiLine contacts">
                                <ng-container *ngIf="GetTheme()==='Dark'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="pointer-events: none;">
                                        <path d="M16 7.99999C16 9.96954 15.2734 11.8699 13.9595 13.3371C12.6455 14.8043 10.8365 15.7352 8.87888 15.9516C6.92125 16.1679 4.9526 15.6545 3.34994 14.5097C1.74727 13.3649 0.62325 11.6691 0.193122 9.7471C-0.237007 7.82509 0.0569932 5.81195 1.01882 4.09322C1.98064 2.37449 3.54269 1.07097 5.40582 0.432296C7.26894 -0.20638 9.30219 -0.135321 11.1162 0.631864C12.77 1.3313 14.1356 2.56452 15 4.12699C15 4.5 15 4.5 15 4.70174L14.367 5H14.1299C13.4071 3.52316 12.1748 2.35574 10.6583 1.71441C9.11087 1.05994 7.37635 0.999318 5.78696 1.54416C4.19757 2.089 2.86502 3.201 2.04451 4.66721C1.224 6.13342 0.973193 7.85078 1.34013 9.49041C1.70706 11.13 2.66594 12.5767 4.03313 13.5533C5.40033 14.5299 7.07974 14.9679 8.74975 14.7833C10.4198 14.5987 11.963 13.8046 13.0839 12.5529C14.2048 11.3013 14.8246 9.68017 14.8246 7.99999H16Z" fill="#C7C7C7"/>
                                        <rect x="14" width="1" height="5" fill="#C7C7C7"/>
                                        <rect x="10" y="4" width="5" height="1" fill="#C7C7C7"/>
                                    </svg>
                                </ng-container>
                                
                                <ng-container *ngIf="GetTheme()!=='Dark'">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="pointer-events: none;">
                                        <path d="M16 7.99999C16 9.96954 15.2734 11.8699 13.9595 13.3371C12.6455 14.8043 10.8365 15.7352 8.87888 15.9516C6.92125 16.1679 4.9526 15.6545 3.34994 14.5097C1.74727 13.3649 0.62325 11.6691 0.193122 9.7471C-0.237007 7.82509 0.0569932 5.81195 1.01882 4.09322C1.98064 2.37449 3.54269 1.07097 5.40582 0.432296C7.26894 -0.20638 9.30219 -0.135321 11.1162 0.631864C12.77 1.3313 14.1356 2.56452 15 4.12699C15 4.5 15 4.5 15 4.70174L14.367 5H14.1299C13.4071 3.52316 12.1748 2.35574 10.6583 1.71441C9.11087 1.05994 7.37635 0.999318 5.78696 1.54416C4.19757 2.089 2.86502 3.201 2.04451 4.66721C1.224 6.13342 0.973193 7.85078 1.34013 9.49041C1.70706 11.13 2.66594 12.5767 4.03313 13.5533C5.40033 14.5299 7.07974 14.9679 8.74975 14.7833C10.4198 14.5987 11.963 13.8046 13.0839 12.5529C14.2048 11.3013 14.8246 9.68017 14.8246 7.99999H16Z" fill="#161616"/>
                                        <rect x="14" width="1" height="5" fill="#161616"/>
                                        <rect x="10" y="4" width="5" height="1" fill="#161616"/>
                                    </svg>
                                </ng-container>
                            </div> -->
                        <movius-web-search-bar
                            headingRight
                            class="messages__search"
                            [class.active]="searchBarActive"
                            (changed)="updateSearch($event)"
                            (isActivated)="onSearchBarActivated($event)">
                        </movius-web-search-bar>
                        <movius-web-ui-cta-button
                            data-cy="new-message-button"
                            *ngIf="!searchBarActive"
                            [ngClass]="messages__topControl"
                            btnType="Message"
                            [isDisabled]="view.status !== 'allowed'"
                            (clicked)="messageComposeRedirect($event)"
                            [routerLink]="['./chat/new']" >
                        </movius-web-ui-cta-button>
                    </div>
                </div>
                <div
                    headingCustom
                    class="messages__heading"
                    *ngIf="appEmbededStatus === 'messaging'">
                    <movius-web-search-bar-message-login
                        class="messages__search"
                        [class.active]="true"
                        (changed)="updateSearch($event)"
                        (isActivated)="onSearchBarActivated($event)">
                    </movius-web-search-bar-message-login>
                </div>
                <ng-container main>
                    <ng-container *ngIf="searchText.length < 2; else searchContent">
                        <div *ngIf="isDeleteActive" class="messages__delete_container">
                            <div class="messages__delete_text__delete_count">
                                {{deleteContactList.size }} of 10
                            </div>
                            <div class="messages__delete_items">
                                <div (click)="OnDeleteButtonClick()" [ngClass]="getConnectionErrorValue ? 'messages__delete_items delete_disabled':'messages__delete_items'" class="messages__delete_items">
                                    <svg width="16" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.25 0.25V1H12V2.5H11.25V12.25C11.25 13.075 10.575 13.75 9.75 13.75H2.25C1.425 13.75 0.75 13.075 0.75 12.25V2.5H0V1H3.75V0.25H8.25ZM2.25 12.25H9.75V2.5H2.25V12.25ZM3.75 4H5.25V10.75H3.75V4ZM8.25 4H6.75V10.75H8.25V4Z" fill="#FF1D1D"/>
                                    </svg>
                                    <div class="messages__delete_text__delete_msg">
                                        Delete
                                    </div>
                                </div>
                                <div (click)="OnCancelDeleteClick()" class="messages__delete_items">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_1688_20182" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
                                        <rect width="18" height="18" class="icon-fill"/>
                                        </mask>
                                        <g mask="url(#mask0_1688_20182)">
                                        <path d="M4.8 14.25L3.75 13.2L7.95 9L3.75 4.8L4.8 3.75L9 7.95L13.2 3.75L14.25 4.8L10.05 9L14.25 13.2L13.2 14.25L9 10.05L4.8 14.25Z" class="icon-fill"/>
                                        </g>
                                    </svg>
                                    <div class="messages__delete_text">
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  *ngIf="!isDeleteActive && (showVoiceMailTab == true)" id="tab_scroll_main" (click)="onFilterTabChange($event,true)" class="search_tab_container">
                            <div [attr.data-value]="Filters.chats" class="tab-message"  [ngClass]="{'tab_is-active': !(messagingService.voiceMailFilter$ | async)}">
                                <p class="tab-title">Messages</p>
                                <div *ngIf="messageNewCount" class="tab-badge">
                                </div>
                            </div>
                            <div [attr.data-value]="Filters.voicemail" class="tab-voicemail" [ngClass]="{'tab_is-active': messagingService.voiceMailFilter$ | async}">
                               <p class="tab-title">Voicemails</p>
                               <div *ngIf="voiceMailnewCount" class="tab-badge">
                                </div>
                            </div>
                        </div>
                        <cdk-virtual-scroll-viewport [itemSize]="64" class="messages__viewport" [ngClass]="{'__viewport_height_del': isDeleteActive}"  >
                            <ng-container *cdkVirtualFor="let session of view.sessions;trackBy: trackByFun;let indexOfelement = index;">

                                <ng-container *ngIf="session?.peer?.id ? true : false">
                                    <communication-item data-cy="message-list-item" class="messages__historyItem {{ deleteContactList.has(session.threadId) && isMobileDevice ? 'chatselected' : ''  }} {{ isMobileDevice ? 'no-padding' : ''  }}" [peer]="session.peer"
                                        [isMuted]="session.isMuted" [isGroup]="session.isGroup" [isFullWidth]="true" [isSelected]="deleteContactList.has(session.threadId)"
                                        [routerLink]="['/messaging/chat/', isMobileDevice ? '' : session.messageChannelType != 'normalMsg' ? session.threadId : session.peerId]"
                                        [routerLinkActive]="['is-active']" (click)="onClickMessagingWorkspace(session)" [id]="generateId(session)+session.thread"
                                        (deleteClick)="OndeleteThreadClick(session,'deleteMenu')"
                                        (deleteNotActive)="onDeleteThreadNotActiveClick()"
                                        [isDeleteActive]="isDeleteActive"
                                        [deleteEnabled]="session.deleteEnabled && isDeleteEnabled && !(messagingService.voiceMailFilter$ | async)"
                                        [voiceMail]="session.voicemail"
                                        (toggleDeleteSelected)="OndeleteThreadClick(session,'selectMenu')"
                                        [mlIdentifierData]="session.mlIdentifierData"
                                        [getConnectionErrorValue]="getConnectionErrorValue"
                                        >
                                        <div class="messages__hstAround" title>
                                            <div class="messages__hstTwoLiner">
                                                <div class="messages__hstFirstLine">
                                                    <div class="messages__hstName" [class.unread--name]="session.newCount > 0  && messagingService.InitialThreadLoaded">
                                                        <message-channel-type-icon [messageChannelType]="session.messageChannelType"
                                                            [alt]="'img'">
                                                        </message-channel-type-icon>
                                                        <!-- <img
                                                                    *ngIf="session.isWhatsAppThread"
                                                                    src="assets/icons/movius/contacts/icons-whatsapp.svg"
                                                                    alt="img"/> -->
                                                        <!-- {{ getCallTitle(session) }} -->
                                                        {{session | contactTitle: savedContact}}
                                                    </div>
                                                    <div class="messages__hstDate" [class.unread--date]="
                                                                    session.newCount > 0
                                                                ">
                                                        {{
                                                        formatHistoryTime(
                                                        session.messages[0]
                                                        ?.sentTime
                                                        )
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="messages__hstSecondLine">
                                                    <div class="messages__hstPreview" *ngIf="
                                                                    session
                                                                        .messages[0] as latestMessage
                                                                ">
                                                        <span *ngIf="
                                                                        latestMessage.from === 'me'
                                                                    ">You:
                                                        </span>
                                                        <span *ngIf="
                                                                        latestMessage.messageType !==
                                                                        'picture'
                                                                    ">
                                                            <img class="vm-img" *ngIf="
                                                                            latestMessage.content ===
                                                                            'Voicemail'
                                                                        " src="assets/icons/movius/audio/icon-vm.svg" alt="vvm" />
                                                            {{ latestMessage.content }}
                                                        </span>
                                                        <span *ngIf="
                                                                        latestMessage.messageType ===
                                                                        'picture'
                                                                    ">
                                                            <img class="pic-img" src="{{GetTheme() == 'Dark' ? 'assets/icons/dark_theme_icons/dark-icons-photo-img.svg': 'assets/icons/movius/messages/icons-photo-img.svg'}}"
                                                                alt="vvm" />
                                                            Photo
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div badge data-cy="new-messages-count" class="messages__unreadCount" [ngClass]="{'messages__blurred':session.isMuted}"
                                            [style.visibility]="
                                                                                (session.newCount === 0 || !messagingService.InitialThreadLoaded)
                                                                                   ? 'hidden'
                                                                                   : 'visible'
                                                                           ">
                                        </div>
                                    </communication-item>
                                </ng-container>
                                <ng-container *ngIf="view.sessions.length == (indexOfelement + 1) ">
                                    <div class="loading-container"  moviusWebThreadScroll (appInfiniteScroll)="nextThreads()"></div>
                                    <div *ngIf="!loadedThread">
                                        <movius-web-spinner></movius-web-spinner>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </ng-container>
                    <ng-template #searchContent>
                        <ng-container
                            *ngTemplateOutlet="
                                messagesList;
                                context: {
                                    $implicit: filteredList,
                                    title: 'Contacts',
                                    viewportHeight:view.viewPortHeights.contactsLength,
                                    convsindex: view.convsindex,
                                    galCntIndex: view.galCntIndex,
                                    convMsgsLength: view.convMsgsLength,
                                    galContactsLength: view.galContactsLength
                                }
                            "
                        ></ng-container>
                        <!-- <ng-container
                            *ngTemplateOutlet="
                                messagesList;
                                context: {
                                    $implicit: view.galContacts,
                                    title: 'Exchange Directory',
                                    viewportHeight:
                                        view.viewPortHeights.galContactsLength,
                                    isGalContact: true
                                }
                            "
                        ></ng-container>
                        <ng-container
                            *ngTemplateOutlet="
                                messagesList;
                                context: {
                                    $implicit: view.sessions,
                                    title: 'Conversation',
                                    viewportHeight:
                                        view.viewPortHeights.conversationsLength
                                }
                            "
                        ></ng-container> -->
                    </ng-template>
                    <div
                        class="messages__emptyShell"
                        *ngIf="view.isEmptySearch"
                    >
                        <div class="messages__emptyIco"></div>
                        <div class="messages__emptyTxt">No Results Found</div>
                    </div>
                    <movius-web-ui-cta-button
                        data-cy="new-message-button"
                        *ngIf="
                            appEmbededStatus === 'messaging' &&
                            isWhatsAppEnabledByOrg
                        "
                        [ngClass]="getChatButtonStyle()"
                        btnType="Message"
                        nz-dropdown
                        [nzDropdownMenu]="messageMenu"
                        nzPlacement="topRight"
                        [isDisabled]="view.status !== 'allowed'"
                    ></movius-web-ui-cta-button>
                    <movius-web-ui-cta-button
                        data-cy="new-message-button"
                        *ngIf="
                            appEmbededStatus === 'messaging' &&
                            !isWhatsAppEnabledByOrg
                        "
                        [ngClass]="getChatButtonStyle()"
                        btnType="Message"
                        [routerLink]="['./chat/new']"
                        (click)="messageComposeRedirect('message')"
                        [isDisabled]="view.status !== 'allowed'"
                    ></movius-web-ui-cta-button>
                </ng-container>
            </pane-frame>
            </div>

        </div>
        <!-- class="messages__splitter messages__splitter--second messages__details" -->
<!--         <div><h1>His - {{isHideChatHistory}}</h1></div>-->
        <div class="messages__splitter messages__splitter--second messages__details" [ngClass]="isMessaging()"
            *ngIf="isHideChatHistory" (isBackBtnClicked)="backBtnClicked($event)">
            <router-outlet></router-outlet>
        </div>
    </div>

    </ng-template>
</div>



<ng-template
#messagesList
let-messages
let-isGalContact="isGalContact"
let-title="title"
let-height="viewportHeight"
let-convsindex="convsindex"
let-galCntIndex="galCntIndex"
let-convMsgsLength="convMsgsLength"
let-galContactsLength="galContactsLength"
>
        <div>
            <div id="search_list" style="position: relative;">
                <div id="tab_scroll" (click)="onFilterTabChange($event)" class="search_tab_container">
                    <div [attr.data-value]="Filters.messages" class="tab-message " [ngClass]="{'tab_is-active': selectedFilter == Filters.messages}" >
                        <p class="tab-title">
                            Messages ({{ filterConversation.length}})
                        </p>
                    </div>
                    <div *ngIf="showVoiceMailTab==true" [attr.data-value]="Filters.voicemail" class="tab-voicemail " [ngClass]="{'tab_is-active': selectedFilter == Filters.voicemail}" >
                        <p class="tab-title">
                            Voicemails ({{ voiceMailFilterConversation.length}})
                        </p>
                    </div>
                    <div [attr.data-value]="Filters.contacts" class="tab-contact " [ngClass]="{'tab_is-active': selectedFilter == Filters.contacts}" >
                        <p class="tab-title">
                            Contacts ({{ filterPrivateContact.length}})
                        </p>
                    </div>
                    <div [attr.data-value]="Filters.exchange" class="tab-directory " [ngClass]="{'tab_is-active': selectedFilter == Filters.exchange}">
                        <p class="tab-title">
                            Directory ({{ galContactsLength}})
                        </p>
                    </div>
                </div>
            </div>
                <div class="multiline_search_container">
                    <div>
                        Search results  {{'('+messages.length+')'}}
                    </div>
                    <div *ngIf="multilinEnabled" class="multiline-toggle-container">
                        <div>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="19" height="19" class="multiline-icon-stroke" />
                            <path
                                d="M6.67822 15V6.56716L6.93612 6.85295L9.97132 9.79587L15 5H12.5687L9.98107 7.46798L7.43618 5H5V15H6.67822Z"
                                class="multiline-icon-fill" />
                            <path
                                d="M9.98117 11.6802L8.00269 9.76209V12.0993L9.97142 14.0085L13.3185 10.8161V14.9999H14.9975V6.89596L9.98117 11.6802Z"
                                class="multiline-icon-fill" />
                        </svg>

                    </div>
                    <div style="margin-left: 10px;" (click)="onMultilineToggle()">
                        <div *ngIf="multilineToggle">
                            <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="36" height="20" rx="10" fill="#5B5FC7" />
                                <circle cx="26" cy="10" r="7" fill="white" />
                            </svg>

                        </div>
                        <div *ngIf="!multilineToggle">
                            <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="35" height="19" rx="9.5" stroke="#424242" />
                                <circle cx="10" cy="10" r="6.5" fill="white" stroke="#424242" />
                            </svg>

                        </div>
                    </div>
                </div>

            </div>
        </div>
        <section *ngIf="messages && messages.length > 0" class="messages__all">
            <cdk-virtual-scroll-viewport [itemSize]="64" class="messages__viewport" [ngClass]="{'__viewport_height_del': isDeleteActive}"  >
                <ng-container *cdkVirtualFor="let message of messages;trackBy: trackByFun;let indexOfelement = index;">
                    <!-- <span>a{{convsindex}}</span>
                    <span>b{{convMsgsLength}}</span>
                    <span>c{{galCntIndex}}</span>
                    <span>d{{galContactsLength}}</span> -->
                    <!-- <div *ngIf="indexOfelement == convsindex && convMsgsLength >= 1">
                        <p class="messages__subCaption">
                            Conversations
                            <ng-container *ngIf="messages.length > 0"
                                >({{ convMsgsLength }})</ng-container
                            >
                        </p>
                    </div> -->

                    <!-- <div *ngIf="indexOfelement == galCntIndex && galContactsLength >= 1">
                        <p class="messages__subCaption">
                            Exchange Directory
                            <ng-container *ngIf="messages.length > 0"
                                >({{ galContactsLength }})</ng-container
                            >
                        </p>
                    </div> -->

                    <!-- <div>
                        <p class="messages__subCaption">
                            {{ title }}
                        </p>
                    </div> -->

                    <!-- <div *ngIf="indexOfelement == convMsgsLength + galContactsLength">
                        <p class="messages__subCaption">
                            {{ title }} -->
                            <!-- <ng-container *ngIf="contacts.length > 0"
                                >({{ contacts.length - freqContactsLength - galContactsLength }})</ng-container
                            > -->
                        <!-- </p>
                    </div> -->

                    <div class="messages__spinner_icon" *ngIf="title === 'Exchange Directory' && messages.length === 0">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                            y="0px" viewBox="0 0 2400 2400" xml:space="preserve" width="24" height="24">
                            <g stroke-width="200" stroke-linecap="round" stroke="#6b6868" fill="none" id="spinner">
                                <line x1="1200" y1="600" x2="1200" y2="100" />
                                <line opacity="0.5" x1="1200" y1="2300" x2="1200" y2="1800" />
                                <line opacity="0.917" x1="900" y1="680.4" x2="650" y2="247.4" />
                                <line opacity="0.417" x1="1750" y1="2152.6" x2="1500" y2="1719.6" />
                                <line opacity="0.833" x1="680.4" y1="900" x2="247.4" y2="650" />
                                <line opacity="0.333" x1="2152.6" y1="1750" x2="1719.6" y2="1500" />
                                <line opacity="0.75" x1="600" y1="1200" x2="100" y2="1200" />
                                <line opacity="0.25" x1="2300" y1="1200" x2="1800" y2="1200" />
                                <line opacity="0.667" x1="680.4" y1="1500" x2="247.4" y2="1750" />
                                <line opacity="0.167" x1="2152.6" y1="650" x2="1719.6" y2="900" />
                                <line opacity="0.583" x1="900" y1="1719.6" x2="650" y2="2152.6" />
                                <line opacity="0.083" x1="1750" y1="247.4" x2="1500" y2="680.4" />
                                <animateTransform attributeName="transform" attributeType="XML" type="rotate"
                                    keyTimes="0;0.08333;0.16667;0.25;0.33333;0.41667;0.5;0.58333;0.66667;0.75;0.83333;0.91667"
                                    values="0 1199 1199;30 1199 1199;60 1199 1199;90 1199 1199;120 1199 1199;150 1199 1199;180 1199 1199;210 1199 1199;240 1199 1199;270 1199 1199;300 1199 1199;330 1199 1199"
                                    dur="0.83333s" begin="0s" repeatCount="indefinite" calcMode="discrete" />
                            </g>
                        </svg>
                    </div>
                    <communication-item
                    data-cy="message-list-item"
                    class="messages__historyItem"
                    [peer]="message.peer"
                    [isGroup]="message.isGroup"
                    [isMuted]="message.isMuted"
                        [routerLink]="activeRouteUrl(message, false)"
                        [routerLinkActive]="['is-active']" [isFullWidth]="true"
                        (click)="onClickMessagingWorkspace(message, selectedFilter == Filters.exchange)" [id]="generateId(session)"
                        [mlIdentifierData]="message.mlIdentifierData"
                        >

                        <div class="messages__hstAround" title [style]="{'height':(selectedFilter!=Filters.contacts && selectedFilter!=Filters.exchange) ?'2rem':'auto'}">
                            <div class="messages__hstTwoLiner">
                                <div class="messages__hstFirstLine">
                                    <div class="messages__hstName" [class.unread--name]="
                                            (message.newCount > 0 && messagingService.InitialThreadLoaded)
                                        ">
                                        <!-- commenting pipe as not getting the expected output -->
                                        <!-- [innerHTML]="session | highlighText: searchText" -->
                                        <message-channel-type-icon
                                            [messageChannelType]="message.messageChannelType"
                                            [alt]="'img'">
                                        </message-channel-type-icon>
                                        <!-- <img src="assets/icons/movius/contacts/icons-whatsapp.svg" alt="img"
                                            *ngIf="message.isWhatsAppThread"> -->
                                        <span [innerHTML]=" message  | contactTitle : savedContact "
                                            *ngIf="!highlightUnsavedContact(message)"> {{ message | contactTitle : savedContact }}
                                        </span>
                                        <span [innerHTML]=" message  | contactTitle : savedContact | highlighText: searchText"
                                            *ngIf="highlightUnsavedContact(message)"> {{ message | contactTitle : savedContact
                                            }}</span>
                                    </div>
                                    <div class="messages__hstDate" [class.unread--date]="
                                            message.newCount > 0
                                        ">
                                        {{
                                        formatHistoryTime(
                                        message.latestMessage?.sentTime
                                        )
                                        }}
                                    </div>
                                </div>
                                <div class="messages__hstSecondLine" *ngIf="(selectedFilter!=Filters.contacts && selectedFilter!=Filters.exchange)">
                                    <div class="messages__hstPreview" *ngIf="message.latestMessage as latestMessage">
                                        <strong *ngIf="latestMessage.from === 'me' && selectedFilter!=Filters.voicemail">You:
                                        </strong>
                                        <span *ngIf="latestMessage.messageType !== 'picture' && selectedFilter!=Filters.voicemail"
                                            [innerHTML]="latestMessage.content | highlighText: searchText">
                                        </span>
                                        <p *ngIf="latestMessage.messageType === 'voicemail' && selectedFilter==Filters.voicemail">
                                            <img class="vm-img"
                                            src="assets/icons/movius/audio/icon-vm.svg" alt="vvm"> Voicemail
                                        </p>
                                        <span *ngIf="latestMessage.messageType === 'picture'">
                                            <img class="pic-img" src="{{GetTheme() == 'Dark' ? 'assets/icons/dark_theme_icons/dark-icons-photo-img.svg': 'assets/icons/movius/messages/icons-photo-img.svg'}}" alt="vvm">
                                            Photo
                                        </span>
                                    </div>
                                    <!-- <div *ngIf="message?.peer?.contact" style="color: var(--movius_text-gray); font-size: 12px; font-weight: 400;">
                                        {{getSessionNumber(message.peer)}}
                                    </div> -->

                                </div>
                            </div>
                        </div>
                        <div data-cy="new-messages-count" class="messages__unreadCount" [style.visibility]="
                                            (message.newCount === 0 || !messagingService.InitialThreadLoaded)
                                                ? 'hidden'
                                                : 'visible'
                                        ">
                        </div>
                    </communication-item>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </section>
    </ng-template>

<nz-dropdown-menu #messageMenu="nzDropdownMenu">
    <ul nz-menu class="messages__dropMenu">
        <li
            nz-menu-item
            class="messages__dropMenuItem"
            (click)="messageComposeRedirect('whatsapp')"
            [routerLink]="['./chat/new']"
        >
            <img
                class="messages__icon-img"
                src="assets/icons/movius/contacts/icons-whatsapp.svg"
                alt="img"
            />
            WhatsApp
        </li>
        <li
            nz-menu-item
            class="messages__dropMenuItem"
            (click)="messageComposeRedirect('message')"
            [routerLink]="['./chat/new']"
        >
            <img
                class="messages__icon-img"
                src="assets/icons/movius/common/icons-contact-message-black.svg"
                alt="img"
            />
            Message
        </li>
    </ul>
</nz-dropdown-menu>
