import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'movius-web-e911-terms-condition',
    templateUrl: './e911-terms-condition.component.html',
    styleUrls: ['./e911-terms-condition.component.scss'],
})
export class E911TermsConditionComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}
}
