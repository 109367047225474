import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { of } from 'rxjs/internal/observable/of';
import { GeoHttpService } from './geo-http.service';
import { DbContext } from './db-context.service';
import { MultiLineIdentifierUserLookup } from '@movius/domain';
import { Contact as ContactDTO , Person as PersonDTO} from '@movius/msgraph';
import { mapFromMsGraphContact, mapFromMsGraphPerson, prefixCountryCodeToContactNumber } from '../utils';
import { BehaviorSubject, from, Observable, Subject, timer } from 'rxjs';
import { debounceTime, filter, mergeMap, take, toArray } from 'rxjs/operators';
import {LoggerFactory} from '@movius/ts-logger';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { lookupContact } from '../ngrx';
const logger = LoggerFactory.getLogger("")
@Injectable({
    providedIn: 'root',
})
export class MliUserLookupService {
    mliUserlookupList:any[]
    mliUserlookupMap = new Map();
    AllNumberList = new Set<string>();
    LookUpNuberSet = new Set();
    LookupFinished = new BehaviorSubject(1);
    constructor(
        private geoHttpService: GeoHttpService,
        private dbContext: DbContext,
        private authService: AuthService,
        private store : Store,
    ) {
    }
    async getMliUserlookupTableList(){
        let userLookupServiceEnabled =  sessionStorage.getItem("userLookupUpServiceEnabled")
        if(userLookupServiceEnabled && userLookupServiceEnabled == "true" ){
        this.mliUserlookupList = await this.dbContext.mliUserLookup.getAllMLIUserLookUp()
        this.mliUserlookupMap = new Map(this.mliUserlookupList.map((res)=> [res.number,res]))
        }
    }
    mapFromUserLookup(number, isMultiLine, isExternalMultiline = false) {
        return {
            number,
            isMultiLine,
            isExternalMultiline,
        } as MultiLineIdentifierUserLookup;
    };
    mliDataForNumbers(numbers){
        let returnData={};
        numbers?.map(nu=>{
            const item = this.mliUserlookupMap.get(nu?.replace(/[\s+]/g,""))||{}
            if(item){
                returnData[nu]=item
            }
        })
        return returnData
    }
    mapFromMsGraphContactAndLookup(dto:ContactDTO, prefixCountryCode:boolean =true){
        let listt = [...dto.homePhones,...dto.businessPhones,dto.mobilePhone].map((m) => {
            if(m){
                let num = prefixCountryCodeToContactNumber(m)
                if(num)
                    return num?.replace(/[\s+]/g,"")
            }
        })
        this.AllNumberList = new Set([...this.AllNumberList,...listt])
        return mapFromMsGraphContact(dto, prefixCountryCode)
    }


    mapFromMsGraphPersonAndLookup(dto:PersonDTO){
        let listt = dto.phones?.map((m) => {
            if(m.number){
                return m.number.replace(/[\s+]/g,"")
            }
        })
        this.AllNumberList = new Set([...this.AllNumberList,...listt])
        return mapFromMsGraphPerson(dto)
    }

    localContactLookup(dto){
        let listt = dto.phones.map((m) => {
            if(m.phone){
                let num = prefixCountryCodeToContactNumber(m.phone)
                if(num)
                    return num?.replace(/[\s+]/g,"")
            }
        })
        this.AllNumberList = new Set([...this.AllNumberList,...listt])
    }

    lookupNumberInContactPhone(phones:any[]){
        let listt = phones.map((m) => {
            if(m && m.phone){
                return m.phone?.replace(/[\s+]/g,"")
            }
        })
        this.AllNumberList = new Set([...this.AllNumberList,...listt])
    }

    addContactToTheSet(number){
        if( !number.includes("whatsapp") && number){
            this.AllNumberList.add(number?.replace(/[\s+]/g,""))
        }
    }

    checkSyncedTime(syncInterval:number){
        try{
            let lastSyncedTime = sessionStorage.getItem("LookUpSyncTime")
            if(lastSyncedTime){
                let currentDate = new Date();
                let syncedTime = new Date(lastSyncedTime);
                let difference = currentDate.getMilliseconds() - syncedTime.getMilliseconds()
                let timeLeft = syncInterval - difference
                if(timeLeft <= 0){
                    logger.debug("[MLI User lookup] Sync time exeeded "+ (syncInterval/1000)*60 +" hours processing force refresh.")
                    return 0
                }else{
                    return timeLeft
                }
            }
            sessionStorage.setItem("LookUpSyncTime",new Date().toString())
            return syncInterval
        }catch(e){
            logger.debug("[MLI User lookup] Check sync time error",e)
            sessionStorage.setItem("LookUpSyncTime",new Date().toString())
            return syncInterval
        }
    }

    scheduleForceRefresh(){
        let syncInterval = window['SYNC_INTERVAL'] ? window['SYNC_INTERVAL'] :   172800000;
        let time = this.checkSyncedTime(syncInterval)
        if(time && typeof time == "number"){
            timer(time,syncInterval).pipe(debounceTime(10000)).subscribe(()=>{
                logger.debug(`[MLI User lookup] Timer called for force lookup. ${time}`)
                this.store.dispatch(lookupContact({force:true}))
            })
        }
    }

    async updateMliUserLookupTable(lookupData) {
        try{
            logger.debug(`[MLI User lookup] Updating lookup table. ${lookupData.multiline?.length}`)
            let multiLineNumbers = lookupData.multiline;
            let externalMultiLineNumbers = lookupData.multiline_external;
            let nonMultiLine = lookupData.non_multiline;
            let unprocessed = lookupData.unprocessed;
            // need to work with unprocessed
            // setting multiline numbers
            multiLineNumbers = multiLineNumbers.map((number) =>
                this.mapFromUserLookup(number, true)
            );
            // setting external multiline numbers
            externalMultiLineNumbers = externalMultiLineNumbers.map((number) =>
                this.mapFromUserLookup(number, false, true)
            );
            // setting non multiline numbers
            nonMultiLine = nonMultiLine.map((number) =>
                this.mapFromUserLookup(number, false)
            );
            unprocessed = unprocessed.map((number) =>
                this.AllNumberList.delete(number)
            );
            let allLookupData = [
                ...multiLineNumbers,
                ...externalMultiLineNumbers,
                ...nonMultiLine,
            ];
            await this.dbContext.mliUserLookup.addOrUpdateMLIUserLookup(allLookupData);
            this.getMliUserlookupTableList()
        } catch(error){
            logger.debug("[MLI User lookup] Updating lookup table failed. Error::", error)
        }
    }

    chunkNumbersList(array, chunkSize = 500) {
        let result = [];

        for (let i = 0; i < array.length; i += chunkSize) {
          result.push(array.slice(i, i + chunkSize).filter((res)=> res && res != null && res.indexOf("+") == -1 ));
        }
        return result;
      }

    getUserLookupAPI(numbers) {
        try{
            let lookupAPICount = sessionStorage.getItem("userLookUpAPICount")
            if(!lookupAPICount){
                sessionStorage.setItem("userLookUpAPICount", "0")
                lookupAPICount="0"
            }
            const chunks = this.chunkNumbersList(Array.from(numbers), 500);
            logger.debug(`[MLI User lookup] Starting process count ${lookupAPICount}`)
            from(chunks).pipe(
                filter((chunk)=>chunk.length > 0),
                mergeMap(chunk =>
                    this.postData(chunk).pipe(
                        map(async response =>
                            {
                        logger.debug(`[MLI User lookup] Chunk size ${chunk.length}`)
                        if(response.error){
                            sessionStorage.setItem("userLookUpAPICount",`${parseInt(lookupAPICount)+1}`)
                            lookupAPICount = `${parseInt(lookupAPICount)+1}`
                            if(parseInt(lookupAPICount)>1){
                                logger.debug("[MLI User lookup] Retry exceeded. Stopping user lookup verification")
                                return
                            }
                            logger.debug("[MLI User lookup] Error",response.error)
                            logger.debug("[MLI User lookup] Fetching user info for new lookup token")
                            let apiName = this.authService.apiName
                            let apiIdentity = this.authService.apiIdentity
                            let apiToken = this.authService.apiAuthToken
                            const authDataAccess = this.authService.getAuthDataAccess
                            const userInfo:any = await authDataAccess.getUserInfo(apiName, apiIdentity, apiToken).toPromise()
                            if(userInfo['root']){
                                userInfo?.root?.access_token ? sessionStorage.setItem("getIdentityToken", userInfo?.root?.access_token) : ""
                                userInfo?.root?.enterprise_uid ? sessionStorage.setItem("enterpriseId", userInfo?.root?.enterprise_uid) : ""
                                logger.debug("[MLI User lookup] Fetching user info for new lookup token complete")
                            }
                            logger.debug("[MLI User lookup] Retrying user lookup verification")
                            this.getUserLookupAPI(numbers)
                        }
                        else if (response.data) {
                            sessionStorage.setItem("userLookUpAPICount","0")
                            this.updateMliUserLookupTable(response.data);
                        }

                    })
                ),
                )
            ).subscribe(() => {
                console.log('[MLI User lookup] All chunks processed')
                this.getMliUserlookupTableList()
                this.LookupFinished.next(this.LookupFinished.getValue() + 1)
                },
                error => {
                    logger.debug('[MLI User lookup] Error processing chunks:', error)
                }
                // get uder infor api token save
            );
        } catch(error){
            logger.debug("[MLI User lookup] Error while processing getUserLookupAPI", error)
        }

    }

    postData(chunk): Observable<any> {
        // let lookupAPICount = sessionStorage.getItem("userLookUpAPICount")
        // if(!lookupAPICount){
        //     sessionStorage.setItem("userLookUpAPICount", "0")
        //     lookupAPICount="0"
        // }
        // else if(parseInt(lookupAPICount)>1){
        //     logger.debug("[MLI User lookup] Retry exceeded. Stopping user lookup verification")
        //     return
        // }
        let eid = sessionStorage.getItem('enterpriseId');
        let x_device_id = localStorage.getItem('movius_device_id');
        let accessToken = sessionStorage.getItem('getIdentityToken');
        let userLookupServiceAddress = sessionStorage.getItem(
            'lookupServiceAddress'
        );
        let apiUrl = `https://${userLookupServiceAddress}/api/v1/user/lookup/eid/${eid}`;
        // let apiUrl = `http://localhost:4000/user/lookup/${eid}`;
        const body = {
            numbers: chunk
          };
        const headers = new HttpHeaders({
          'x-device-id': x_device_id,
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ accessToken,
        //   'Authorization': 'Bearer '+"eyJhbGciOiJIUzI1NiJ9.eyJkZXZpY2VfaWQiOiIxOWVhNjBjZi00OWUxLTRlYTUtOTk3YS1iZDU4ZWIxNTk5Y2EiLCJvcmdpZCI6Nzk5NywidWlkIjoiN2EwM2Q5NmMtZjNmOS00NThmLWIwNTMtNjU5OTA3ZWI3OTQ0Iiwicm9sZSI6InVzZXIiLCJleHAiOjE3MjE3NDQ0MjZ9.FvNgDhUr0Nx0YiF3SAz7ut8ADW6TVoMY2w6kt3Wi9dM"

        });
        return this.geoHttpService.callLookUpApi(apiUrl,"post",body,headers)
        // return this.http.post<any>(`${apiUrl}`, body, { headers });
      }
}
