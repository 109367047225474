<div>
    <h3 class="enine_header"><b>SPRINT CUSTOMER AFFIRMATIVE ACKNOWLEDGMENT OF 9-1-1 SERVICE AND LIMITATIONS</b></h3>

    <p><b>Sprint Wi-Fi Calling Service</b> (“the Service”) completes telephone calls using Voice over Internet Protocol (“VoIP”) technology by connecting a capable Sprint wireless device to a router using a Wi-Fi signal and is intended primarily for use within the domestic United States (“U.S”). VoIP telephony is fundamentally different from traditional telephone service and has inherent limitations. The Service, including 9-1-1 calling, may be unavailable or limited in some circumstances, including without limitation the circumstances described below. While the Service may be available in a limited number of countries outside the U.S., calls to 9-1-1 and other international emergency service abbreviated dialing codes, such as 1-1-2 or 9-9-9, WILL NOT function over Wi-Fi and should not be used or relied upon during an emergency. <u>Customer certifies awareness of the Service limitations outlined below and that Service will not be used by anyone other than Customer without first notifying the end user of such limitations.</u></p>
    <p>1. <b>9-1-1 Limitations of Service.</b></p>
        <p class="inner-points">1. <b>Relocation of End User Devices.</b> If Customer uses the Service in a location other than the Registered Location for that device, 9-1-1 calls may not be routed to the appropriate Public Safety Answering Point (“PSAP”) for the end user's current physical location.</p>
        <p class="inner-points">2. <b>Use of “Non-Native” Telephone Numbers.</b> If Customer uses the Service with an assigned telephone number that is outside the rate center of the Registered Location, the PSAP for the Registered Location may not recognize the telephone number for call-back or other informational purposes.
        <p class="inner-points">3. <b>Broadband Connection Failures.</b> The Service will not be able to make calls if Customer loses connectivity to the Internet. Due to Internet congestion and network design issues, 9-1-1 calls placed through the Service may sometimes produce a busy signal, experience unexpected answering wait times, or take longer to answer than 9-1-1 calls placed through traditional telephone networks.</p>    
        <p class="inner-points">4. <b>Loss of Electrical Power.</b> The Service will not operate if Customer has lost electric power for the Service or for other Service-enabling equipment. After a power outage, Customer may need to reset or reconfigure enabling equipment or devices before being able to use the Service.
        <p class="inner-points">5. <b>Updating Registered Locations in ALI Databases.</b> If Customer does not correctly identify the physical location of the Service when defining the Registered Location, 9-1-1 calls through the Service may not reach the correct PSAP. At initial activation of the Service, and following any update to Registered Locations, there may be some delay before complete and accurate information is passed to the local emergency service operator.</p>    
    <p>2. <b>Registered Locations.</b> Sprint relies on the Registered Location of the Service at the time a 9-1-1 call is placed to route the call to the appropriate PSAP within the domestic U.S. and to provide the PSAP with your location. Your device may automatically attempt to determine the Registered Location information based on its location. You should verify and update the Registered Location information regularly and when accessing a Wi-Fi network with the same name (SSID) that may exist in multiple locations. Registered Location information is not automatically updated for a location with the same network name or for other non-cellular devices being used with the Service. If Customer does not update the Registered Location or it is not complete, Sprint may attempt to route a 9-1-1 call based on earlier Registered Location information, which may not match Customer's actual location and may cause a 9-1-1 call to be misrouted and/or provide a PSAP with incorrect location information. Always be prepared to provide your actual location to a call taker.</p>
    <p>3. <b>PSAP Limitations.</b> The PSAP designated to receive 9-1-1 calls for a particular Registered Location through the Service may not have a system configured for all 9-1-1 services. The PSAP may not be able to capture, retain or otherwise determine the phone number, Registered Location, or physical location of the VoIP device placing the 9-1-1 call. Accordingly, Customer must be prepared to provide this information to the PSAP. Until and unless Customer does so, the emergency service operator may be unable to call Customer back or to otherwise assist Customer in the event of an emergency.</p>
    <p>4. <b>Warning Labels and Certifications.</b> Customer must notify end users of the Service about the 9-1-1 limitations of the Service as outlined in this acknowledgement. Sprint provides Customer with warning labels regarding the limitations or unavailability of 9-1-1 services. Customer will place labels on or near each VoIP device used to access the Service. Customer will acknowledge and complete all advisory notices and certifications received from Sprint regarding 9-1-1 service. Click <a style="color:blue;">here</a> to download a warning label.</p>
    <p>5. <b>Customer Choice.</b> Each Customer must carefully evaluate the individual circumstances in deciding whether to rely solely upon the Service for 9-1-1 calling or to make necessary provisions for access to emergency calling services (e.g. maintaining a conventional landline phone as a backup means of completing emergency calls).</p>
    <p>6. <b>Other Limitations.</b> In addition to 9-1-1 limitations, the Service does not support Wireless Emergency Alerts and may not support other wireless products and services. Other non-cellular devices capable of Wi-Fi Calling may not support a 9-1-1 call over a wireless carrier network and may be limited to Wi-Fi Calling capabilities only, if available and connected. The primary device user can deactivate Service on all other associated devices at any time and thereby eliminate Wi-Fi Calling, including 9-1-1 calling, from those devices.</p>
    <p>7. <b>Limitation of Liability and Release.</b> Sprint is not liable for any failure to access emergency services when using the Service. Customer hereby releases and discharges Sprint, its affiliates and subsidiaries, and their respective officers, directors, employees, agents and vendors affiliated with the Service from any and all claims, losses, damages, fines, penalties, costs, expenses and liability arising out of Customer's or any third party's use of the Service, and hereby acknowledges that Sprint is not liable for any failure or outage of the Service, including those related to 9-1-1 dialing in the USA and Emergency Services Calling in other countries where the Service is permitted by Sprint. This release and waiver extends to all claims of any kind or nature whether foreseen, known or unknown.</p>
<hr>
    <p>©2018 Movius Corporation Inc. Movius and associated logos are trademarks or registered trademarks of Movius Corporation Inc.</p>
</div>