import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLongPress]'
})
export class LongPressDirective {
  @Output() longPress = new EventEmitter<void>();
  private pressTimer: any;
  private longPressDuration = 700; // Duration in milliseconds (1 second)

  constructor(private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onPressStart(event: Event): void {
    this.renderer.addClass(document.body, 'no-select');

    this.pressTimer = setTimeout(() => {
        this.longPress.emit()
        this.renderer.removeClass(document.body, 'no-select');
    }, this.longPressDuration);
  }

  @HostListener('mouseup')
  @HostListener('mouseleave')
  @HostListener('touchend')
  @HostListener('touchcancel')
  onPressEnd(): void {
    clearTimeout(this.pressTimer);
  }
}
