<div class="cntDetails__library">
    <p class="cntDetails__libCaption">Details</p>
    <div
        nz-row
        *ngFor="let item of userInfoFields | keyvalue: originalOrder"
        class="cntDetails__item"
    >
        <div class="cntDetails__column" nz-col [nzSpan]="8">
            <span class="cntDetails__description">{{ capitalizeFirstLetter(item.key) }} </span>
        </div>
        <div class="cntDetails__column" nz-col [nzSpan]="16" style="display: flex;align-items: center; gap: 1rem;">
            <span class="cntDetails__data">{{ getDetailsValue(item.value)}}</span>
            <div *ngIf="mliUserLookupInfo[item.value]?.isMultiLine" style="width: 16px;height: 16px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect x="0.5" y="0.5" width="15" height="15" fill="#FF4E3B" stroke="#F8F9FA"/>
                    <path d="M5.02039 12.4898V4.9177L5.25178 5.17431L7.97503 7.81684L12.4869 3.5105H10.3055L7.98378 5.72656L5.70045 3.5105H3.51465V12.4898H5.02039Z" fill="white"/>
                    <path d="M7.98412 9.50878L6.20898 7.78644V9.88513L7.97538 11.5994L10.9785 8.73287V12.4897H12.4849V5.21286L7.98412 9.50878Z" fill="white"/>
                    </svg>
            </div>
            <!-- <div *ngIf="mliUserLookupInfo[item.value]?.isExternalMultiline" style="width: 18px;height: 18px; ">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="#007AFF" stroke="white"/>
                    <path d="M6.02039 13.4898V5.9177L6.25178 6.17431L8.97503 8.81684L13.4869 4.5105H11.3055L8.98378 6.72656L6.70045 4.5105H4.51465V13.4898H6.02039Z" fill="white"/>
                    <path d="M8.98412 10.5088L7.20898 8.78644V10.8851L8.97538 12.5994L11.9785 9.73287V13.4897H13.4849V6.21286L8.98412 10.5088Z" fill="white"/>
                    </svg>
            </div> -->
        </div>
    </div>
    <div *ngIf="keys(userInfoFields).length == 0">
      <div class="cntDetails__column" nz-col [nzSpan]="8">
        <span class="cntDetails__description">BusinessPhone</span>
    </div>
    <div class="cntDetails__column" nz-col [nzSpan]="16">
        <span class="cntDetails__data"></span>
    </div>
    </div>
</div>