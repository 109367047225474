
<div *ngIf="deleteActive && !isMobileDevice"  class="commitem__select">
    <div class="disabled-select" *ngIf="message.messageType == 'voicemail'" > </div>
    <div *ngIf="message.messageType !== 'voicemail'" class="icon-container" [ngClass]="{'selected': isSelected}" (click)="toggleSelect($event)">
        <svg class="checkmark" width="24" height="22" viewBox="0 0 24 22" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="11" r="11" fill="#5B5FC7" />
            <path
                d="M10.8955 15.9363C10.5486 15.9363 10.2937 15.8018 10.0601 15.5186L7.18555 11.929C7.00146 11.7166 6.93066 11.5183 6.93066 11.2988C6.93066 10.8174 7.29175 10.4634 7.78735 10.4634C8.08472 10.4634 8.29712 10.5696 8.49536 10.8245L10.8672 13.8831L15.4692 6.56226C15.6816 6.23657 15.887 6.11621 16.2268 6.11621C16.7224 6.11621 17.0693 6.45605 17.0693 6.9375C17.0693 7.12866 17.0127 7.31982 16.8711 7.53931L11.731 15.4973C11.5327 15.7947 11.2566 15.9363 10.8955 15.9363Z"
                fill="white" />
        </svg>
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12 22C18.0751 22 23 17.0751 23 11C23 4.92487 18.0751 0 12 0C5.92487 0 1 4.92487 1 11C1 17.0751 5.92487 22 12 22ZM12 20.5C17.2467 20.5 21.5 16.2467 21.5 11C21.5 5.75329 17.2467 1.5 12 1.5C6.75329 1.5 2.5 5.75329 2.5 11C2.5 16.2467 6.75329 20.5 12 20.5Z"
                fill="#C7C7CC" />
        </svg>
    </div>
</div>
<div class="chat-frame">

    <div class="chat-unread-frame" *ngIf="(message.isFirstNotRead !== false)" id="unread_messages_element">
        <!-- <div class="chat-unread-frame__unread-item-liner">
            <div
                id="unread_messages_element"
                class="chat-unread chat-unread-frame__unread-item"
                (click)="scrollToBottom.emit()"
            >
                <div class="chat-unread__arrow"></div>
                <div class="chat-unread__text">
                    {{ afterReadCountUpdate }} Unread Messages
                </div>
            </div>
        </div> -->
    </div>

    <div class="chat-date" *ngIf="message.isDateBefore">
        {{ message.dateFormatted }}
    </div>
    <div class="chat-message" (mouseenter)="isActive = true" (mouseleave)="isActive = false">
        <div class="chat-message__itemFrame" [ngClass]="{ 'chat-message__itemFrame--self': isSelf, 'chat-message__itemFrame--mobile': isMobileDevice }">

            <div class="chat-message__item" [ngClass]="{
                    'chat-message__item--self': isSelf,
                    'chat-message__item--system--whatsapp': message?.isSystem && chatId?.includes('whatsapp:') && !message?.content?.startsWith('[Automated Message]'),
                    'chat-message__item--system': message?.isSystem && message?.content?.startsWith('[Automated Message]')
                }">
                <div class="chat-message__timestamp" [ngClass]="{ 'chat-message__timestamp--self': isSelf }">
                    <div class="chat-message__sentFrom"
                        *ngIf="message.from !== 'me' && isGroupChat === true && !message?.isSystem && !message?.content?.includes('the conversation.')">
                        {{ getContactName(message.fromNumber) }},&nbsp;

                    </div>
                    <div class="chat-message__timeValue"></div>
                    {{ formatTime(message.sentTime) }}

                </div>
                <div class="chat-message__textFrame {{ isSelected && isMobileDevice ? 'isSelected' : ''}}"
                    *ngIf="message?.messageType !== 'voicemail' && message?.messageType !== 'picture'">
                    <!-- <div class="chat-message__textFrame"> -->
                    <div
                        [ngClass]="message?.isSystem  && isWhatsAppThread && !message?.content?.startsWith('[Automated Message]') ? 'chat-message__infoicon-textFrame' : 'chat-message__infoicon-textFrame-cont'">
                        <span class="chat-message__infoicon"
                            *ngIf="message?.isSystem  && isWhatsAppThread && !message?.content?.startsWith('[Automated Message]') ">
                            <img src="assets/icons/movius/common/info (1).svg"
                                alt="" srcset="">
                        </span>
                        <div [ngClass]="message?.isSystem  && isWhatsAppThread && !message?.content?.startsWith('[Automated Message]') ? 'chat-message__text--systemMsg ' : isMobileDevice ? 'chat-message__text--mobile' : 'chat-message__text'"
                            [innerHTML]="(message?.content?.includes('Looks like this contact') && !isthislastmessage(message.sentTime))
                                                    ? 'Looks like this contact hasnt enabled WhatsApp.' :  (message?.content?.includes('Last message') && message?.isSystem)
                                                    ? replacestring(message.content)  : message.content
                                                     | linky: { newWindow: true } | highlighText: ngStoreService.searchItems">
                        </div>
                    </div>

                    <div class="chat-message__textFrame"
                        *ngIf="(message?.content?.startsWith('Looks like this contact')
                    && (!timecrossed(message.sentTime) || (optincrossed(optInRequestCount) && isthislastmessage(message.sentTime))))">
                        <div>
                            <p></p>
                        </div>
                    </div>

                    <div *ngIf="noWAthread(message)" class="chat-message__sms-query-text-frame">
                        <img src="'assets/icons/movius/common/info (1).svg"
                            alt="" srcset="">
                        <p class="chat-message__sms-query-text">Do you want to send an SMS instead?</p>
                    </div>

                    <div class="chat-message__optInReqButton" *ngIf="noWAthread(message)">
                        <!-- <input class="chat-message__optInReqButton-input" type="button" value="No"
                            (click)="onNoOptionForEnableWhatsApp()" />
                        <span class="chat-message__dividerLine"></span> -->
                        <input class="chat-message__optInReqButton-input" type="button" value="Yes"
                            (click)="onYesOptionForEnableWhatsApp(peer_multiLine)" />
                    </div>

                    <div style="margin-top:20px;"
                        *ngIf="message?.content?.startsWith('Looks like this contact') &&
                    timecrossed(message.sentTime) && !optincrossed(optInRequestCount) && !isMoreThen1Day(message.sentTime)">
                        <!--<p class="chat-message__text-input">Currently, the contact hasn't enabled WhatsApp. You may try sending the request again now.</p>-->

                        <p class="chat-message__sms-query-text-frame">
                            <img src="assets/icons/movius/common/info (1).svg" alt=""
                                srcset="">&nbsp;&nbsp;&nbsp;&nbsp;Currently, the contact hasn't enabled WhatsApp.
                        </p>
                    </div>
                    <!--<div class="chat-message__optInReqButton"  *ngIf="message.content.startsWith('To Re-Engage the conversation')">
                        <input class="chat-message__optInReqButton-input" type="button" value="Re-Engage Request" (click)="resendOptInRequest(cnt_id, peer_multiLine)"/>
                    </div>-->
                    <!--<button
                        [disabled]="disableOptinRequest"
                        *ngIf="noWAResend(message,whatsOptInReqStatus)"
                        class="chat-message__optInReqButton"
                        (click)="resendOptInRequest(cnt_id,peer_multiLine)">
                        Resend Opt-in Request
                    </button>

                    <button
                        [disabled]="disableOptinRequest"
                        *ngIf="(message.isSystem && (message.content.includes('You can try sending the request again.')
                        || (message.content.includes('Currently, the contact hasnt enabled WhatsApp'))) &&
                        optInRequestCountCheck(optInRequestCount, whatsOptInReqStatus, message.sentTime))"
                        class="chat-message__optInReqButton"
                        (click)="resendOptInRequest(cnt_id,peer_multiLine)">
                        Resend Opt-in Request
                    </button>-->
                </div>

                <div *ngIf="message.messageType == 'voicemail'">
                    <movius-web-audio-player class="chat-audio-message__audioItemFrame"
                        [message]="message?.messageInfo"></movius-web-audio-player>
                </div>

                <!-- <div class="chat-message__picture"
                [ngClass]="{
                    'chat-message__item--self':isSelf,
                    'chat-message__loading':showToggleSpinner,
                    'chat-message__image-size':(!showToggleSpinner && multimediaType != 'image/gif'),
                    'chat-message__gif-size':(!showToggleSpinner && multimediaType == 'image/gif')
                }"
                                *ngIf="message.messageType == 'picture'">
                <img  *ngIf="showToggleSpinner === false && multimediaType == 'image/gif'"  class="chat-message__spinner" src="assets/icons/movius/common/gif.svg"  (click)="imagePreview(imageUrl)" alt="GIF">
                <img class="chat-message__spinner" *ngIf="showToggleSpinner === true" src="assets/icons/movius/audio/icons_spinner_blue.svg" alt="Spinner">
                <img  *ngIf="showToggleSpinner === false" class="chat-message__img" [src]= "imageUrl" (click)="imagePreview(imageUrl)">
                </div> -->
                <div class="chat-message__picture" [ngClass]="{
                    'chat-message__item--self':isSelf,
                    'isSelected':isSelected && isMobileDevice
                    }" [ngStyle]="{
                    'height': isMobileDevice ? '200px' : '250px',
                    'width': isMobileDevice ? '250px' : '350px',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'center'
                    }" *ngIf="message.messageType == 'picture'">
                    <img *ngIf="showToggleSpinner === false && multimediaType == 'image/gif'"
                        class="chat-message__spinner" src="assets/icons/movius/common/gif.svg"
                        (click)="imagePreview(imageUrl)" alt="GIF">
                    <img class="chat-message__spinner" *ngIf="showToggleSpinner === true"
                        src="assets/icons/movius/audio/icons_spinner_blue.svg" alt="Spinner">
                    <img *ngIf="showToggleSpinner === false" class="chat-message__img"
                        [ngStyle]="{'object-fit':'cover'}" [src]="imageUrl" (click)="imagePreview(imageUrl)">
                </div>

                <div class="chat-message__error-frame" nz-popover nzPopoverTrigger="click"
                    [nzPopoverContent]="message.state.kind === 'PictureMessageAPIError' || imageDownloadStatus ? '' : notSentTemplate">
                    <div class="chat-message__error-bulb"
                        *ngIf="message.state.kind === 'MessageStateError' || message.state.kind === 'PictureMessageAPIError' || message.state.kind === 'PicMsgRetryThresholdReached'  || message.isOfflineMessage"
                        [ngClass]="{ 'chat-message__error-bulb--self': isSelf }"></div>
                    <div class="chat-message__error-msg" data-cy="error-message"
                        *ngIf="message.state.kind === 'MessageStateError' || message.state.kind === 'MessageStateInvalid' || message.state.kind === 'PicMsgRetryThresholdReached'">
                        Not sent
                    </div>
                    <div class="chat-message__error-msg" data-cy="error-message" *ngIf="imageDownloadStatus">
                        Failed to download
                    </div>
                    <div class="chat-message__error-msg" data-cy="error-message"
                        *ngIf="message.state.kind === 'PictureMessageAPIError' || message.isOfflineMessage">
                        Sending
                    </div>
                </div>
                <ng-template #notSentTemplate>
                    <div [ngClass]="isMobileDevice ? 'chat-message__notSent-mobile': 'chat-message__notSent'"
                        *ngIf="message.state.kind === 'MessageStateError'">
                        <p class="chat-message__notSentTitle">
                            No Internet Connection
                        </p>
                        <p [ngClass]="isMobileDevice ? 'chat-message__notSentMsg-mobile': 'chat-message__notSentMsg'">
                            Message not delivered. Looks like you have lost internet
                            connection
                        </p>
                    </div>
                    <div class="chat-message__notSent" *ngIf="message.state.kind === 'PicMsgRetryThresholdReached'">
                        <p [ngClass]="isMobileDevice ? 'chat-message__notSentMsg-mobile': 'chat-message__notSentMsg'">
                            Image is not sent. Please try again!!
                        </p>
                    </div>
                    <div class="chat-message__notSentDisplayNone"
                        *ngIf="message.state.kind !== 'PicMsgRetryThresholdReached' || message.state.kind !== 'MessageStateError'">
                    </div>
                </ng-template>
            </div>
            <div *ngIf=" isDeleteEnabled || (!isDeleteEnabled && !message.isSystem)" data-cy="chat-item-dropdown" class="chat-message__dropdown"
                [ngClass]="{ 'chat-message__dropdown--self': isSelf }" [style.visibility]="isActive &&
                (message.messageType !== 'voicemail'
                && !imageDownloadStatus) ? 'visible' : 'hidden'" nz-dropdown [nzDropdownMenu]="menu"
                [nzPlacement]="'bottomLeft'"></div>

            <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu class="chat-message__dropMenu" (mouseleave)="isActive = false"
                    (mouseenter)="isActive = true">
                    <li *ngIf="(isLogingViaTeams !== 'true' || message.messageType == 'text') && !message.isSystem" nz-menu-item
                        class="chat-message__dropMenuItem"
                        (click)="copy.emit()">
                        Copy
                    </li>
                    <li *ngIf="message.messageType == 'picture' && !isMobileDevice && !message.isSystem" nz-menu-item
                        class="chat-message__dropMenuItem"
                        [ngClass]="getConnectionErrorValue === false && showToggleSpinner === false ? 'chat-message__dropMenuItem' : 'chat-message__dropMenuItem-disabled'">
                        <a [href]="imageUrl" download="{{imageFileName}}">Download</a>
                    </li>
                    <!--<li nz-menu-item class="chat-message__dropMenuItem">Reply</li>-->
                    <li *ngIf="!chatId.includes('whatsapp:') && !message.isSystem" nz-menu-item
                        [ngClass]="(message.messageType !== 'picture' || message.messageType === 'picture' && showToggleSpinner === false)? 'chat-message__dropMenuItem' : 'chat-message__dropMenuItem-disabled'"
                        (click)="forward.emit()">
                        Forward
                    </li>
                    <li
                        *ngIf="isDeleteEnabled"
                        data-cy="chat-item-remove-message"
                        nz-menu-item
                        [ngClass]="getConnectionErrorValue === false ? 'chat-message__dropMenuItem' : 'chat-message__dropMenuItem-disabled'"
                        (click)="onDeleteClick()"
                    >
                        Delete
                    </li>
                </ul>
            </nz-dropdown-menu>
        </div>
    </div>
</div>


<movius-web-general-failure class="chat-message__displayNone" (passConnectionError)="getConnectionError($event)">
</movius-web-general-failure>
