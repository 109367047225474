<div class="help__box">
    <p   [ngClass]="isMobileDevice  ? 'help__subTitle_mobile' : 'help__subTitle'" >Help</p>


    <div  [ngClass]="isMobileDevice  ? 'help__item_mobile' : 'help__item'">
        <img [src]="GetTheme() ? 'assets/icons/Calls_dark.svg' : 'assets/icons/Calls.svg'" />
        <div class="help__container">
            <div class="help__text">
                Call
            </div>
            <div >
                <a class="help__link" [href]="teamsCallUrl">
                    {{customerSupportPhone}}
                </a>
            </div>
        </div>
    </div>
    <div  [ngClass]="isMobileDevice  ? 'help__item_mobile' : 'help__item'">
        <img  [src]="GetTheme() ? 'assets/icons/mail_outline_dark.svg' : 'assets/icons/mail_outline.svg'" />
        <div class="help__container">
            <div class="help__text">
                Email
            </div>
            <div class="help__link" (click)="submitDetailsELKServer()">
                {{customerSupportEmail}}
            </div>
        </div>
    </div>
    <div  [ngClass]="isMobileDevice  ? 'help__item_mobile_noliner' : 'help__item'">
        <img [src]="GetTheme() ? 'assets/icons/knowledge_base_dark.svg' : 'assets/icons/knowledge_base.svg'" />
        <div class="help__container">
            <div class="help__text">
                Knowledge base
            </div>
            <div >
                <a class="help__link" target="_blank" [href]="customerSupportWebsite">
                    {{customerSupportWebsiteText}}
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="isMobileDevice" class="help__item_cancel" (click)="closeModal()">
        <div class="help__container">
            <div class="help__text_cancel">
                Cancel
            </div>
        </div>
    </div>


<movius-web-general-failure class="help__display-none"
(passConnectionError)="getConnectionError($event)">
</movius-web-general-failure>
