<div class="e911Terms">
    <p class="e911Terms__text">To make use of calling functionality, Please accept the <b routerLink="/settings/e911">E911
        Terms & Conditions</b>
    </p>
</div>
<div class="e911Terms__controls">
    <button class="e911Terms__decline" (click)="destroyModal(false)">
        Decline
    </button>
    <button class="e911Terms__accept" (click)="destroyModal(true)">
        Accept
    </button>
</div>