// Generated by https://quicktype.io

export interface People {
    '@odata.context': string;
    value: Person[];
}

export interface Person {
    id: string;
    displayName: string;
    givenName: null | string;
    surname: null | string;
    birthday: null | string;
    personNotes: null | string;
    isFavorite: boolean;
    jobTitle: null | string;
    companyName: null | string;
    yomiCompany: null | string;
    department: null | string;
    officeLocation: null | string;
    profession: null | string;
    userPrincipalName: null | string;
    imAddress: null | string;
    scoredEmailAddresses: ScoredEmailAddress[];
    phones: Phone[];
    personType: PersonType;
}

export interface PersonType {
    class: PersonClass;
    subclass: Subclass;
}

export enum PersonClass {
    Group = 'Group',
    Person = 'Person',
}

export enum Subclass {
    OrganizationUser = 'OrganizationUser',
    UnifiedGroup = 'UnifiedGroup',
}

export interface Phone {
    type: string;
    number: string;
}

export interface ScoredEmailAddress {
    address: string;
    relevanceScore: number;
    selectionLikelihood: SelectionLikelihood;
}

export enum SelectionLikelihood {
    NotSpecified = 'notSpecified',
}
