import { UserContact } from '../../../feature-contacts/models/contact';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    ElementRef,
    HostListener,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'communication-item',
    templateUrl: './communication-item.component.html',
    styleUrls: ['./communication-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationItemComponent implements OnInit {

    @Output() onBackCLicked = new EventEmitter();

    @Input()
    logoUrl: string;

    @Input()
    isVertical: boolean;

    @Input()
    addCustomLogo = false;

    @Input()
    removeLogo = false;

    @Input()
    externalStyle: Object;

    @Input()
    peer: UserContact;

    @Input()
    isMuted: boolean;

    @Input()
    isFullWidth: boolean = false;

    @Input()
    showBackOption:boolean = false;

    @Input()
    isGroup:boolean;

    @Input()
    isSelected: boolean = false;


    @Input()
    isDeleteActive: boolean = false;

    @Input()
    deleteEnabled: boolean = false;

    isMobileDevice: boolean;
    @Input()
    mlIdentifierData?:any;
    @Input()
    getConnectionErrorValue?:any;

    @Output() deleteClick = new EventEmitter();
    @Output() deleteNotActive = new EventEmitter();

    @Output() toggleDeleteSelected = new EventEmitter();


    constructor() {
        this.isMobileDevice = ((sessionStorage.getItem('Contex_res')?.toLowerCase() === "ios") || (sessionStorage.getItem('Contex_res')?.toLowerCase() === "android"))
            ? true : false;
    }

    GetTheme(){
        let theme = localStorage.getItem("Theme")
        return theme ? "Dark" : null
    }

    ngOnInit(): void {}

    onDeleteClick(){
        if(this.getConnectionErrorValue) return
        this.deleteClick.emit()
    }
    toggleSelect(event:MouseEvent){
        event.stopPropagation();
        this.toggleDeleteSelected.next()
    }
    onLongPress(event){
        if(!this.isMobileDevice)
            return
        if(this.deleteEnabled && this.isMobileDevice){
            this.deleteClick.emit()
        }else{
            this.deleteNotActive.emit()
        }
    }

    handleMobileDelete(event){
        if(this.isDeleteActive && this.isMobileDevice){
            event.stopPropagation();
            if(this.deleteEnabled)
                this.deleteClick.emit()
        }
    }
}
