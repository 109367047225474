<div  appLongPress (longPress)="onLongPress($event)" (click)="handleMobileDelete($event)" class="commitem {{ isVertical ? 'commitem--vertical' : ''  }} " hover-class="hovered" [ngStyle]="externalStyle">
    <div *ngIf="isDeleteActive && deleteEnabled && !isMobileDevice" class="commitem__select">
        <div class="icon-container" [ngClass]="{'selected': isSelected}" (click)="toggleSelect($event)">
            <svg class="checkmark" width="24" height="22" viewBox="0 0 24 22" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="11" r="11" fill="#5B5FC7" />
                <path
                    d="M10.8955 15.9363C10.5486 15.9363 10.2937 15.8018 10.0601 15.5186L7.18555 11.929C7.00146 11.7166 6.93066 11.5183 6.93066 11.2988C6.93066 10.8174 7.29175 10.4634 7.78735 10.4634C8.08472 10.4634 8.29712 10.5696 8.49536 10.8245L10.8672 13.8831L15.4692 6.56226C15.6816 6.23657 15.887 6.11621 16.2268 6.11621C16.7224 6.11621 17.0693 6.45605 17.0693 6.9375C17.0693 7.12866 17.0127 7.31982 16.8711 7.53931L11.731 15.4973C11.5327 15.7947 11.2566 15.9363 10.8955 15.9363Z"
                    fill="white" />
            </svg>
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 22C18.0751 22 23 17.0751 23 11C23 4.92487 18.0751 0 12 0C5.92487 0 1 4.92487 1 11C1 17.0751 5.92487 22 12 22ZM12 20.5C17.2467 20.5 21.5 16.2467 21.5 11C21.5 5.75329 17.2467 1.5 12 1.5C6.75329 1.5 2.5 5.75329 2.5 11C2.5 16.2467 6.75329 20.5 12 20.5Z"
                    fill="#C7C7CC" />
            </svg>
        </div>
    </div>
    <div *ngIf="isDeleteActive && !deleteEnabled && !isMobileDevice" class="commitem__select_empty">
    </div>
    <ng-content select="[badge]" class="badge-count"></ng-content>
    <svg [ngClass]="isGroup ? 'commitem__align_item_group' : 'commitem__align_item'"
        *ngIf="showBackOption && isMobileDevice && GetTheme() == null" (click)="onBackCLicked.emit()" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="80.593px" height="122.88px" viewBox="0 0 80.593 122.88" enable-background="new 0 0 80.593 122.88"
        xml:space="preserve">
        <g>
            <polygon points="80.593,0 49.771,0 0,61.44 49.771,122.88 80.593,122.88 30.82,61.44 80.593,0" />
        </g>
    </svg>
    <svg [ngClass]="isGroup ? 'commitem__align_item_group' : 'commitem__align_item'"
        *ngIf="showBackOption && isMobileDevice && GetTheme() == 'Dark'" (click)="onBackCLicked.emit()" version="1.1"
        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="80.593px" height="122.88px" viewBox="0 0 80.593 122.88" fill="#eaeaea" xml:space="preserve">
        <g>
            <polygon points="80.593,0 49.771,0 0,61.44 49.771,122.88 80.593,122.88 30.82,61.44 80.593,0" />
        </g>
    </svg>
    <!-- TODO: CB:27Jan2021: TECH-DEBT: Logo part seems to be overchanged. Consider refactoring. -->
    <ng-container *ngIf="!removeLogo">
        <div *ngIf="peer; then thenBlock; else elseBlock"></div>
        <ng-template #thenBlock>
            <movius-web-contact-logo
                class="contact-logo--small"
                [isMuted]="isMuted"
                [peer]="peer"
                alt="Peer Logo"
                [isGroup]="isGroup"
                [mlIdentifierData]="mlIdentifierData"
                [isDeleteActive]="isDeleteActive"
                [deleteEnabled]="deleteEnabled"
                [isMobileDevice]="isMobileDevice"
                [isSelected]="isSelected"
            ></movius-web-contact-logo>
        </ng-template>
        <ng-template #elseBlock>
            <!-- TODO: CB:27Jan2021: TECH-DEBT: LogoUrl is left here for back-compatability. Consider to remove. -->
            <movius-web-contact-logo
                class="contact-logo--small"
                *ngIf="!!logoUrl && !addCustomLogo"
                [isMuted]="isMuted"
                [peer]="{ dummyImg: logoUrl }"
                [alt]="'Logo'"
                [isGroup]="isGroup"
                [mlIdentifierData]="mlIdentifierData"
                [isDeleteActive]="isDeleteActive"
                [deleteEnabled]="deleteEnabled"
                [isMobileDevice]="isMobileDevice"
                [isSelected]="isSelected"

            ></movius-web-contact-logo>
            <movius-web-contact-logo
                class="contact-logo--small"
                *ngIf="!logoUrl && !addCustomLogo"
                [peer]="{}"
                [isMuted]="isMuted"
                [alt]="'Empty Logo'"
                [isGroup]="isGroup"
                [mlIdentifierData]="mlIdentifierData"
                [isDeleteActive]="isDeleteActive"
                [deleteEnabled]="deleteEnabled"
                [isMobileDevice]="isMobileDevice"
                [isSelected]="isSelected"

            ></movius-web-contact-logo>
            <div class="commitem__customLogo" *ngIf="addCustomLogo">
                <ng-content select="[customLogo]"></ng-content>
            </div>
        </ng-template>
    </ng-container>
    <div class="commitem__content" [class.full-width]="isFullWidth">
        <ng-content select="[title]" class="commitem__title"></ng-content>
        <ng-content select="[subtitle]" class="commitem__subtitle"></ng-content>
    </div>
    <div class="commitem__addition">
        <ng-content select="[addition]"></ng-content>
    </div>
    <div *ngIf="deleteEnabled && !isMobileDevice" class="commitem__menu">
        <div>
            <svg nz-dropdown [nzDropdownMenu]="menuList" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1437_48226" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <rect width="24" height="24" class="icon-fill" />
                </mask>
                <g mask="url(#mask0_1437_48226)">
                    <path
                        d="M6 14C5.45 14 4.97917 13.8042 4.5875 13.4125C4.19583 13.0208 4 12.55 4 12C4 11.45 4.19583 10.9792 4.5875 10.5875C4.97917 10.1958 5.45 10 6 10C6.55 10 7.02083 10.1958 7.4125 10.5875C7.80417 10.9792 8 11.45 8 12C8 12.55 7.80417 13.0208 7.4125 13.4125C7.02083 13.8042 6.55 14 6 14ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM18 14C17.45 14 16.9792 13.8042 16.5875 13.4125C16.1958 13.0208 16 12.55 16 12C16 11.45 16.1958 10.9792 16.5875 10.5875C16.9792 10.1958 17.45 10 18 10C18.55 10 19.0208 10.1958 19.4125 10.5875C19.8042 10.9792 20 11.45 20 12C20 12.55 19.8042 13.0208 19.4125 13.4125C19.0208 13.8042 18.55 14 18 14Z"
                        class="icon-fill" />
                </g>
            </svg>
        </div>
        <nz-dropdown-menu #menuList="nzDropdownMenu">
            <ul nz-menu class="chatspace__dropMenu">
                <li nz-menu-item  [ngClass]="getConnectionErrorValue === false ? 'commitem__dropMenuItem chatspace__dropMenuItem' : 'commitem__dropMenuItem chatspace__dropMenuItem disabledDelete'" (click)="onDeleteClick()">
                    <div class="commitem__dropIcon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.81432 10.1873C7.77367 9.53326 8.29317 8.98047 8.94849 8.98047H16.3253C16.9807 8.98047 17.5002 9.53326 17.4595 10.1873L16.9256 18.7794C16.8883 19.3784 16.3916 19.8452 15.7914 19.8452H9.48246C8.88223 19.8452 8.38551 19.3784 8.34828 18.7794L7.81432 10.1873Z"
                                class="icon-stroke" />
                            <path
                                d="M10.7559 7.63661C10.7559 7.00901 11.2646 6.50024 11.8922 6.50024H13.5799C14.2075 6.50024 14.7163 7.00901 14.7163 7.63661V8.11771C14.7163 8.7453 14.2075 9.25407 13.5799 9.25407H11.8922C11.2646 9.25407 10.7559 8.7453 10.7559 8.11771V7.63661Z"
                                class="icon-stroke" />
                            <path d="M6.63681 9.23151V9.05014V9.23151Z" class="icon-stroke" stroke-width="1.63636" />
                            <path
                                d="M10.7595 12.167C10.7595 11.8338 11.0296 11.5637 11.3628 11.5637C11.696 11.5637 11.9661 11.8338 11.9661 12.167V16.5241C11.9661 16.8573 11.696 17.1274 11.3628 17.1274C11.0296 17.1274 10.7595 16.8573 10.7595 16.5241L10.7595 12.167Z"
                                class="icon-fill" />
                            <path
                                d="M13.3733 12.167C13.3733 11.8338 13.6434 11.5637 13.9766 11.5637C14.3098 11.5637 14.5799 11.8338 14.5799 12.167V16.5241C14.5799 16.8573 14.3098 17.1274 13.9766 17.1274C13.6434 17.1274 13.3733 16.8573 13.3733 16.5241L13.3733 12.167Z"
                                class="icon-fill" />
                        </svg>
                    </div>
                    <div>
                        Delete
                    </div>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
</div>
