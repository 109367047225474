import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { flatten } from 'lodash';
import { sortBy } from 'lodash/fp';
import { UserContact } from '../../../feature-contacts';
import { MessagingDataAccessService } from '../../../feature-messaging';
import { getMultiLineNumber, MliUserLookupService, Peer } from '../../../shared';
import { ActiveCall, PeerCallingState } from '../../models';
import { BehaviorSubject } from 'rxjs';

export type PopoverCall = ActiveCall & { peer: UserContact };

@Component({
    selector: 'movius-web-popover-call-stack',
    templateUrl: './popover-call-stack.component.html',
    styleUrls: ['./popover-call-stack.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverCallStackComponent {
    private _sessions: PeerCallingState[];
    calls: PopoverCall[];
    @Output() accept = new EventEmitter<string>();
    @Output() cancel = new EventEmitter<string>();
    @Output() swap = new EventEmitter<string>();
    @Output() mute = new EventEmitter<{
        callId: string;
        isMute: boolean;
    }>();
    @Output() hold = new EventEmitter<{
        callId: string;
        isHold: boolean;
    }>();
    refreshLookUp = new BehaviorSubject(1);
    constructor(public readonly cdr: ChangeDetectorRef,
        private messagingDataAccessService: MessagingDataAccessService,
        private mliUserLookupService:MliUserLookupService,    
    ) {}

    ngOnInit(): void {
        this.messagingDataAccessService.setVMStatus(true);
    }
    @Input() set sessions(val: PeerCallingState[]) {
        this._sessions = val;
        let mliNumberList = []
        const calls = flatten(
            val.map((m) => m.active.map((x) => ({ ...x, peer: m.peer })))
        );
        val.map(call=>{
            if(call.active.length>0 && !this.mliUserLookupService.mliUserlookupMap.get(call.peer.id)){
                mliNumberList.push(call.peer.id)
                this.mliUserLookupService.addContactToTheSet(call.peer.id)
            }
        })
        this.calls = sortBy((call) => {
            if (call.kind === 'OngoingActiveCall') {                
                if (call.status === 'active') {
                    // active always on top
                    return 1;
                } else {
                    // active but on hold next
                    return 2;
                }                
            } else {
                if (call.direction === 'outgoing') {
                    return 3;
                } else {
                    // incoming always at the bottom
                    return 4;
                }
            }
        }, calls);
        if(mliNumberList.length>0){
            this.mliUserLookupService.getUserLookupAPI(mliNumberList)
        }
        this.mliUserLookupService.LookupFinished.subscribe((val)=>{
            this.calls = this.calls.map(call=>this.getMLIdentifierData(call))
        })
    }

    get sessions() {
        return this._sessions;
    }

    trackBySession(_, session: PeerCallingState) {
        return session.peer.id;
    }

    trackByCall(_, call: ActiveCall) {
        return call.callId;
    }
    getMLIdentifierData(session){
        // if the thread is a group returns multiline since group can only be created by multiline numbers
        try{
            let mlData:any = {}
            let mlNumber = getMultiLineNumber(session.peer.multiLine)
            if(session.peer.contact){
                // if the session has contact the contact phones will have data with the ml identifier
                mlData = session.peer.contact.phones.find(phone=>phone.phone?.replace("+","")===mlNumber?.replace("+",""))
                if(mlData && mlData.isMultiLine == undefined){
                    mlData = this.mliUserLookupService.mliUserlookupMap.get(mlNumber?.replace("+","")) || {}
                    session.peer.contact.phones = session.peer.contact.phones.map(phone=>{
                    let mlDataC = this.mliUserLookupService.mliUserlookupMap.get(phone.phone?.replace("+","")) || {}
                        return {
                            ...phone,
                            ...mlDataC
                        }
                    })

                }
            }
            else{
                // if the contact dont have a contact we check if the number is in the lookup list and get the data
                mlData = this.mliUserLookupService.mliUserlookupMap.get(mlNumber?.replace("+","")) || {}
            }
            session.peer = {...session.peer, mlIdentifierData:mlData}
            return  {
                ...session,
                mlIdentifierData:mlData
            }
        }
        catch(e){
            return session
        }
    }
}
