<div
    class="contacts"
    [class.contacts--topSpaced]="getConnectionErrorValue === true"
    *ngIf="view$ | async as view"
>
<div style="display: flex; flex-direction: column;width: 100%; height: 100%;">
    <movius-web-general-failure
        (passConnectionError)="getConnectionError($event)"
    ></movius-web-general-failure>
    <div [ngClass] = "getStyleClass()">
    <movius-web-sync-contacts
        *ngIf="view.showSyncContactsPlaceholder && isContactSync === false else contactsContent"
    >
    </movius-web-sync-contacts>
    <ng-template #contactsContent>
        <div
            class="contacts__splitter contacts__splitter--first contacts__general"
        >
            <pane-frame
                class="contacts__pane"
                [includeDefaultHeader]="false"
                [includeDefaultFooter]="false"
                [isBodyCentered]="view.isContactsEmpty"
            >
                <div headingCustom class="contacts__heading">
                    <div
                        class="contacts__caption"
                        [class.hidden]="view.isSearchBarActivated"
                    >
                        Contacts
                    </div>
                    <div
                        class="contacts__headingRight"
                        [class.active]="view.isSearchBarActivated"
                    >
                        <div  *ngIf="!view.isSearchBarActivated" class="mli_refresh" (click)="forceRefreshMLIData()" data-tooltip="Sync MultiLine contacts">
                            <ng-container *ngIf="GetTheme()==='Dark'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="pointer-events: none;">
                                    <path d="M16 7.99999C16 9.96954 15.2734 11.8699 13.9595 13.3371C12.6455 14.8043 10.8365 15.7352 8.87888 15.9516C6.92125 16.1679 4.9526 15.6545 3.34994 14.5097C1.74727 13.3649 0.62325 11.6691 0.193122 9.7471C-0.237007 7.82509 0.0569932 5.81195 1.01882 4.09322C1.98064 2.37449 3.54269 1.07097 5.40582 0.432296C7.26894 -0.20638 9.30219 -0.135321 11.1162 0.631864C12.77 1.3313 14.1356 2.56452 15 4.12699C15 4.5 15 4.5 15 4.70174L14.367 5H14.1299C13.4071 3.52316 12.1748 2.35574 10.6583 1.71441C9.11087 1.05994 7.37635 0.999318 5.78696 1.54416C4.19757 2.089 2.86502 3.201 2.04451 4.66721C1.224 6.13342 0.973193 7.85078 1.34013 9.49041C1.70706 11.13 2.66594 12.5767 4.03313 13.5533C5.40033 14.5299 7.07974 14.9679 8.74975 14.7833C10.4198 14.5987 11.963 13.8046 13.0839 12.5529C14.2048 11.3013 14.8246 9.68017 14.8246 7.99999H16Z" fill="#C7C7C7"/>
                                    <rect x="14" width="1" height="5" fill="#C7C7C7"/>
                                    <rect x="10" y="4" width="5" height="1" fill="#C7C7C7"/>
                                </svg>
                            </ng-container>
                            
                            <ng-container *ngIf="GetTheme()!=='Dark'">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="pointer-events: none;">
                                    <path d="M16 7.99999C16 9.96954 15.2734 11.8699 13.9595 13.3371C12.6455 14.8043 10.8365 15.7352 8.87888 15.9516C6.92125 16.1679 4.9526 15.6545 3.34994 14.5097C1.74727 13.3649 0.62325 11.6691 0.193122 9.7471C-0.237007 7.82509 0.0569932 5.81195 1.01882 4.09322C1.98064 2.37449 3.54269 1.07097 5.40582 0.432296C7.26894 -0.20638 9.30219 -0.135321 11.1162 0.631864C12.77 1.3313 14.1356 2.56452 15 4.12699C15 4.5 15 4.5 15 4.70174L14.367 5H14.1299C13.4071 3.52316 12.1748 2.35574 10.6583 1.71441C9.11087 1.05994 7.37635 0.999318 5.78696 1.54416C4.19757 2.089 2.86502 3.201 2.04451 4.66721C1.224 6.13342 0.973193 7.85078 1.34013 9.49041C1.70706 11.13 2.66594 12.5767 4.03313 13.5533C5.40033 14.5299 7.07974 14.9679 8.74975 14.7833C10.4198 14.5987 11.963 13.8046 13.0839 12.5529C14.2048 11.3013 14.8246 9.68017 14.8246 7.99999H16Z" fill="#161616"/>
                                    <rect x="14" width="1" height="5" fill="#161616"/>
                                    <rect x="10" y="4" width="5" height="1" fill="#161616"/>
                                </svg>
                            </ng-container>
                        </div>
                        <movius-web-search-bar
                            #searchBar
                            headingRight
                            class="contacts__search"
                            [class.active]="view.isSearchBarActivated"
                            (isActivated)="
                                onSearchBarIsActivatedChanged($event)
                            "
                            (changed)="updateSearch($event)"
                        ></movius-web-search-bar>
                        <movius-web-ui-cta-button
                            #rla="routerLinkActive"
                            *ngIf="!view.isSearchBarActivated"
                            class="contacts__topControl"
                            data-cy="add-contact-button"
                            [btnType]="'Contact'"
                            [isDisabled]="rla?.isActive"
                            [routerLink]="['./add']"
                            routerLinkActive
                        >
                        </movius-web-ui-cta-button>
                    </div>
                </div>
                <div class="contacts__main" main>
                    <!-- <ng-container
                        *ngTemplateOutlet="
                            contactsList;
                            context: {
                                $implicit: view.frequentlyUsedContacts,
                                title: 'Frequent Contacts',
                                viewportHeight:view.viewPortHeights.frequentlyUsedContacts
                            }
                        "
                    ></ng-container> -->
                    <ng-container
                        *ngTemplateOutlet="
                            contactsList;
                            context: {
                                $implicit: view.privateContacts,
                                title: 'All Contacts',
                                viewportHeight:view.viewPortHeights.privateContacts,
                                freqindex: view.freqindex,
                                galCntIndex: view.galCntIndex,
                                freqContactsLength: view.freqContactsLength,
                                galContactsLength: view.galContactsLength
                            }
                        "
                    ></ng-container>
                    <!-- <ng-container
                        *ngTemplateOutlet="
                            contactsList;
                            context: {
                                $implicit: view.galContacts,
                                title: 'Exchange Directory',
                                viewportHeight: view.viewPortHeights.galContacts
                            }
                        "
                    ></ng-container> -->

                    <div
                        class="contacts__emptyShell"
                        *ngIf="view.isContactsEmpty"
                    >
                        <div class="contacts__emptyIco"></div>
                        <div class="contacts__emptyTxt">No contacts</div>
                    </div>
                </div>
            </pane-frame>
        </div>
        <div
            class="contacts__splitter contacts__splitter--second contacts__details"
        >
            <router-outlet></router-outlet>
        </div>
    </ng-template>

<ng-template
    #contactsList
    let-contacts
    let-title="title"
    let-height="viewportHeight"
    let-freqindex="freqindex"
    let-galCntIndex="galCntIndex"
    let-freqContactsLength="freqContactsLength"
    let-galContactsLength="galContactsLength"
>
    <section *ngIf="contacts.length > 0" class="contacts__all">


        <cdk-virtual-scroll-viewport [itemSize]="64" class="contacts__viewport"  >
            <ng-container *cdkVirtualFor="let contact of contacts;trackBy: trackByContact;let indexOfelement = index;">

                <div *ngIf="indexOfelement == freqindex && freqContactsLength >= 1">
                    <p class="contacts__subCaption">
                        Frequent Contacts
                        <ng-container *ngIf="contacts.length > 0"
                            >({{ freqContactsLength }})</ng-container
                        >
                    </p>
                </div>

                <div *ngIf="indexOfelement == galCntIndex && galContactsLength >= 1">
                    <p class="contacts__subCaption">
                        Exchange Directory
                        <ng-container *ngIf="contacts.length > 0"
                            >({{ galContactsLength }})</ng-container
                        >
                    </p>
                </div>

                <div *ngIf="indexOfelement == freqContactsLength + galContactsLength">
                    <p class="contacts__subCaption">
                        {{ title }}
                        <ng-container *ngIf="contacts.length > 0"
                            >({{ contacts.length - freqContactsLength - galContactsLength }})</ng-container
                        >
                    </p>
                </div>

                <communication-item
                data-cy="contact-item"
                class="contacts__contactItem"
                [peer]="contact"
                (click)="$event.preventDefault(); onContactClicked(contact)"
                [routerLink]="['.', contact.id || contact.msGraphId]"
                [routerLinkActive]="['is-active']"
                [mlIdentifierData]="contact.mlIdentifierData"
            >
                <div title class="contacts__title">
                    <div class="contacts__titleTxt">
                        {{ getContactFriendlyName(contact) }}
                    </div>
                </div>
            </communication-item>
            </ng-container>
        </cdk-virtual-scroll-viewport>


    </section>
</ng-template>
</div>
</div>
