<ng-container class="clogo" *ngIf="this.view as mainView">
    <div *ngIf="mainView.hasImage && !isGroup" class="clogo__hasLogo logo-avatar" [class]="externalStyleClass"
        [style.backgroundImage]="'url(' + mainView.img + ')'">

        <div *ngIf="isDeleteActive && deleteEnabled && isMobileDevice && isSelected">
            <ng-container  *ngTemplateOutlet="delete; context: { isDeleteActive : isDeleteActive,deleteEnabled : deleteEnabled,isMobileDevice : isMobileDevice}"></ng-container>
        </div>
        <div *ngIf="!(isDeleteActive && deleteEnabled && isMobileDevice && isSelected)">
            <ng-container *ngTemplateOutlet="multiline; context: { multiline: mlIdentifierData?.isMultiLine }"></ng-container>
        </div>

    </div>
    <div *ngIf="!mainView.hasImage && mainView.hasAnyName && !isGroup" class="clogo__letters logo-avatar"
        [class]="externalStyleClass" [style.zoom]="getZoom()"
        [style.background-color]="getColorForName(mainView.firstName)">
        <div class="clogo__letterOne" *ngIf="mainView.hasFirstName">
            {{mainView.firstName?.trim().substring(0, 1).toLocaleUpperCase()}}
        </div>
        <div class="clogo__letterTwo" *ngIf="mainView.hasLastName">
            {{mainView.lastName?.trim().substring(0, 1).toLocaleUpperCase()}}
        </div>

        <div *ngIf="isDeleteActive && deleteEnabled && isMobileDevice && isSelected" class="icon_top">
            <ng-container  *ngTemplateOutlet="delete; context: { isDeleteActive : isDeleteActive,deleteEnabled : deleteEnabled,isMobileDevice : isMobileDevice}"></ng-container>
        </div>
        <div *ngIf="!(isDeleteActive && deleteEnabled && isMobileDevice && isSelected)">
            <ng-container *ngTemplateOutlet="multiline; context: { multiline: mlIdentifierData?.isMultiLine }"></ng-container>
        </div>

        <!-- <div *ngIf="mlIdentifierData?.isExternalMultiline" style="width: 18px;height: 18px;  position: absolute; bottom: 0; right:0;">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="#007AFF" stroke="white"/>
                <path d="M6.02039 13.4898V5.9177L6.25178 6.17431L8.97503 8.81684L13.4869 4.5105H11.3055L8.98378 6.72656L6.70045 4.5105H4.51465V13.4898H6.02039Z" fill="white"/>
                <path d="M8.98412 10.5088L7.20898 8.78644V10.8851L8.97538 12.5994L11.9785 9.73287V13.4897H13.4849V6.21286L8.98412 10.5088Z" fill="white"/>
                </svg>
        </div> -->
    </div>
    <div *ngIf="!mainView.hasImage && !mainView.hasAnyName && !isGroup" class="clogo__hasNoLogo logo-avatar"
        [class]="externalStyleClass" [style.zoom]="getZoom()"
        [style.backgroundImage]="GetTheme() == 'Dark' ? 'url(\'assets/icons/dark_theme_icons/avatar.svg\')':'url(\'assets/icons/movius/common/icons-avatar.svg\')'">

        <div *ngIf="isDeleteActive && deleteEnabled && isMobileDevice && isSelected">
            <ng-container  *ngTemplateOutlet="delete; context: { isDeleteActive : isDeleteActive,deleteEnabled : deleteEnabled,isMobileDevice : isMobileDevice}"></ng-container>
        </div>
        <div *ngIf="!(isDeleteActive && deleteEnabled && isMobileDevice && isSelected)">
            <ng-container *ngTemplateOutlet="multiline; context: { multiline: mlIdentifierData?.isMultiLine }"></ng-container>
        </div>


        <!-- <div *ngIf="mlIdentifierData?.isExternalMultiline" style="width: 18px;height: 18px;  position: absolute; bottom: 0; right:0;">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="#007AFF" stroke="white"/>
            <path d="M6.02039 13.4898V5.9177L6.25178 6.17431L8.97503 8.81684L13.4869 4.5105H11.3055L8.98378 6.72656L6.70045 4.5105H4.51465V13.4898H6.02039Z" fill="white"/>
            <path d="M8.98412 10.5088L7.20898 8.78644V10.8851L8.97538 12.5994L11.9785 9.73287V13.4897H13.4849V6.21286L8.98412 10.5088Z" fill="white"/>
            </svg>
    </div> -->
    </div>
    <div *ngIf="isGroup" class="clogo__hasNoLogo logo-avatar" [class]="externalStyleClass"
        [style.backgroundImage]="'url(\'assets/icons/movius/messages/icons-all-group.svg\')'">

        <div *ngIf="isDeleteActive && deleteEnabled && isMobileDevice && isSelected">
            <ng-container  *ngTemplateOutlet="delete; context: { isDeleteActive : isDeleteActive,deleteEnabled : deleteEnabled,isMobileDevice : isMobileDevice}"></ng-container>
        </div>
        <div *ngIf="!(isDeleteActive && deleteEnabled && isMobileDevice && isSelected)">
            <ng-container *ngTemplateOutlet="multiline; context: { multiline: mlIdentifierData?.isMultiLine }"></ng-container>
        </div>


        <!-- <div *ngIf="mlIdentifierData?.isExternalMultiline" style="width: 18px;height: 18px;  position: absolute; bottom: 0; right:0;">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <rect x="0.5" y="0.5" width="17" height="17" rx="8.5" fill="#007AFF" stroke="white"/>
                <path d="M6.02039 13.4898V5.9177L6.25178 6.17431L8.97503 8.81684L13.4869 4.5105H11.3055L8.98378 6.72656L6.70045 4.5105H4.51465V13.4898H6.02039Z" fill="white"/>
                <path d="M8.98412 10.5088L7.20898 8.78644V10.8851L8.97538 12.5994L11.9785 9.73287V13.4897H13.4849V6.21286L8.98412 10.5088Z" fill="white"/>
                </svg>
        </div> -->
    </div>
    <div class="clogo__muted logo-avatar" *ngIf="isMuted">
        <div class="clogo__mutedIco"
            [style.backgroundImage]="'url(\'assets/icons/movius/common/icons-mute-bell.svg\')'">
        </div>
        <div *ngIf="isDeleteActive && deleteEnabled && isMobileDevice && isSelected">
            <ng-container  *ngTemplateOutlet="delete; context: { isDeleteActive : isDeleteActive,deleteEnabled : deleteEnabled,isMobileDevice : isMobileDevice}"></ng-container>
        </div>
    </div>
    <ng-content></ng-content>
</ng-container>

<ng-template #multiline let-multiline="multiline">
    <div *ngIf="multiline" style="width: 16px;height: 16px; position: absolute;
    right: -5px;
    bottom: -5px;"
            class="multiline_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" style="
            position: absolute; inset:0;">
                <rect x="0.5" y="0.5" width="15" height="15" fill="#FF4E3B" class="multiline_icon_stroke" />
                <path
                    d="M5.02039 12.4898V4.9177L5.25178 5.17431L7.97503 7.81684L12.4869 3.5105H10.3055L7.98378 5.72656L5.70045 3.5105H3.51465V12.4898H5.02039Z"
                    fill="white" />
                <path
                    d="M7.98412 9.50878L6.20898 7.78644V9.88513L7.97538 11.5994L10.9785 8.73287V12.4897H12.4849V5.21286L7.98412 9.50878Z"
                    fill="white" />
            </svg>
        </div>
  </ng-template>

  <ng-template #delete let-isDeleteActive="isDeleteActive" let-deleteEnabled="deleteEnabled" let-isMobileDevice="isMobileDevice">
    <div *ngIf="isDeleteActive && deleteEnabled && isMobileDevice && isSelected" class="commitem__select" style="width: 16px;height: 16px; position: absolute;">
        <div class="icon-container" [ngClass]="{'selected': isSelected}" (click)="toggleSelect($event)">
            <svg class="checkmark" width="24" height="22" viewBox="0 0 24 22" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="11" r="11" fill="#5B5FC7" />
                <path
                    d="M10.8955 15.9363C10.5486 15.9363 10.2937 15.8018 10.0601 15.5186L7.18555 11.929C7.00146 11.7166 6.93066 11.5183 6.93066 11.2988C6.93066 10.8174 7.29175 10.4634 7.78735 10.4634C8.08472 10.4634 8.29712 10.5696 8.49536 10.8245L10.8672 13.8831L15.4692 6.56226C15.6816 6.23657 15.887 6.11621 16.2268 6.11621C16.7224 6.11621 17.0693 6.45605 17.0693 6.9375C17.0693 7.12866 17.0127 7.31982 16.8711 7.53931L11.731 15.4973C11.5327 15.7947 11.2566 15.9363 10.8955 15.9363Z"
                    fill="white" />
            </svg>
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12 22C18.0751 22 23 17.0751 23 11C23 4.92487 18.0751 0 12 0C5.92487 0 1 4.92487 1 11C1 17.0751 5.92487 22 12 22ZM12 20.5C17.2467 20.5 21.5 16.2467 21.5 11C21.5 5.75329 17.2467 1.5 12 1.5C6.75329 1.5 2.5 5.75329 2.5 11C2.5 16.2467 6.75329 20.5 12 20.5Z"
                    fill="#C7C7CC" />
            </svg>
        </div>
    </div>
  </ng-template>
