<pane-frame class="optIn" [includeDefaultHeader]="false" [includeDefaultFooter]="false">
    <div mainCustom class="optIn__main">
        <section class="optIn__header">
            <div class="optIn__hCaption">{{ headerTitle }}</div>
            <div class="optIn__hClose" (click)="onClose()"></div>
        </section>
        <section class="optIn__section">
            <div class="optIn__part">
                <cdk-virtual-scroll-viewport [itemSize]="64" class="optIn__viewport" [style.height.px]="800">
                    <ng-container *ngFor ="let contact of messagingThreadList">
                        <div class="optIn__cntShell" [class.inactive]="contact.whatsOptInReqStatus == '4' || contact.whatsOptInReqStatus == '5'" (click)="onSelectedThread(contact.threadId)">
                            <movius-web-contact-logo class="optIn__cntLogo" [peer]="getPeerDataList(contact)" [isGroup]="contact.isGroup"
                                [doZoomLetters]="true" alt="Contact logo">
                            </movius-web-contact-logo>
                            <div class="optIn__cntInfo">
                                <div class="optIn__cntName">
                                    <!-- <img src="assets/icons/movius/contacts/icons-whatsapp.svg" alt="img" *ngIf="contact.isWhatsAppThread"> -->
                                    <message-channel-type-icon
                                        [messageChannelType]="contact.messageChannelType"
                                        [alt]="'img'">
                                    </message-channel-type-icon>

                                    {{ contact | contactTitle : savedContact }}
                                </div>
                                <div class="optIn__cntMsg">
                                    <img *ngIf="contact.messages[0].messageType == 'picture'" src="{{GetTheme() == 'Dark' ? 'assets/icons/dark_theme_icons/dark-icons-photo-img.svg': 'assets/icons/movius/messages/icons-photo-img.svg'}}" alt="img" />
                                    {{showContentMsg(contact.messages[0])}}
                                </div>
                            </div>
                            <div class="optIn__userInfo" nz-dropdown [nzDropdownMenu]="messageMenu" [nzPlacement]="'bottomRight'" *ngIf="contact.isGroup && !isMobileDevice">
                                <svg class="optIn__profileInfo" (click)="preventClick($event)" width="5px" height="13px" viewBox="0 0 5 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Messages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Group-Messages:-Group-Participants" transform="translate(-1156.000000, -187.000000)" fill="#FFFFFF">
                                            <g id="user" transform="translate(676.500000, 159.000000)">
                                                <g id="Combined-Shape" transform="translate(479.500000, 28.000000)">
                                                    <path d="M3.57717846,4.31641868 L3.57717846,10.8224554 L5,10.8224554 L5,13 L9.9998e-05,13 L-1.33226763e-14,10.8451374 L1.4150217,10.8451374 L1.4150217,6.48605741 L-1.33226763e-14,6.48605741 L-1.33226763e-14,4.31641868 L3.57717846,4.31641868 Z M2.1602068,7.10542736e-15 C2.94924102,0.00493294889 3.58595328,0.652169689 3.58790324,1.44939788 C3.57423102,2.26241252 2.91701666,2.91359713 2.11235775,2.91163144 C1.3301484,2.88991999 0.708085838,2.24071563 0.712932284,1.44939788 C0.729562909,0.651184615 1.3701726,0.0098760011 2.1602068,7.10542736e-15 Z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <nz-dropdown-menu #messageMenu="nzDropdownMenu">
                            <div class="optIn__dropMenu">
                                <div class="optIn__dropMenuHeader">Participants</div>
                                <div class="optIn__dropMenuItem" *ngFor="let participant of getParticipantsList(contact.participants)">
                                    <movius-web-contact-logo
                                        class="optIn__cntLogo"
                                        [peer]="getParticipantImage(participant)"
                                        [doZoomLetters]="true"
                                        alt="Contact logo">
                                    </movius-web-contact-logo>
                                    <div class="optIn__participantsContent">
                                        <div class="optIn__participantsHeader">
                                            <span>
                                                <!-- <img *ngIf="participant.includes('whatsapp:')" src="assets/icons/movius/contacts/icons-whatsapp.svg" alt="img"
                                                    class="optIn__whatsapp_icon" /> -->
                                                <message-channel-type-icon
                                                    [messageChannelType]="getChannelType(participant)"
                                                    [alt]="'img'">
                                                </message-channel-type-icon>
                                            </span>
                                            {{getParticipantNameOrNumber(participant).substring(0, 23) + (getParticipantNameOrNumber(participant).length > 24 ? '...' : '')}}
                                        </div>
                                        <div class="optIn__participantsSubHeader" *ngIf="participant !== apiUserIdentity && !getParticipantNameOrNumber(participant).includes(getMultlineNumber(participant)) ">
                                            {{getMultlineNumber(participant)}}
                                        </div>
                                    </div>
                                </div>
                                <div class="optIn__breakLine"></div>
                            </div>
                        </nz-dropdown-menu>
                    </ng-container>
                </cdk-virtual-scroll-viewport>
            </div>
        </section>
        <section class="optIn__controls" *ngIf="showActionBtns">
            <button class="optIn__actionBtn" (click)="onOpenNewChat()">
                {{ actionBtnTitle }}
            </button>
        </section>
    </div>
</pane-frame>
