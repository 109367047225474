<pane-frame
    class="privacy-sets privacy-sets__pane"
    [includeDefaultHeader]="false"
    [includeDefaultFooter]="false"
>
    <div class="privacy-sets__header" headingCustom>
        <div class="privacy-sets__headerTxt">About</div>
    </div>
    <div mainCustom class="privacy-sets__main">
        <div>
                <div><b>Version</b></div>
                <div class="spacing">You have Multiline Messaging version <b>{{Version}}</b></div>
                <div class="spacing">You've got the latest version. It was last updated on<b>{{date}}</b> </div>
                <div class="spacing">The client version is <b>{{version()}}</b></div>
        </div>
    </div>
</pane-frame>

