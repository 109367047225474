import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MessagingState, PeerChatMessageView, selectMessageSearchText, selectMessagesStatus, selectPeerMessagesAndStatuses } from '../../ngrx';
import {
  map,
  takeUntil,
  filter
} from 'rxjs/operators';
import { CallingStatus, MessagingStatus, selectCallingStatus, selectMessagingStatus } from '../../../shared';
import { PeerChatMessage, StateStatusLoadedSeq } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class NgstoreService {
  public selectMessageSearchText$: Observable<string>;
  public searchItems: string = "";

  constructor(
    private readonly store: Store,
  ) {
    this.selectMessageSearchText$ = this.store.pipe(select(selectMessageSearchText))
    this.selectMessageSearchText$.subscribe(resp => {
      this.searchItems = resp ? resp : '';
    }
    );
  }

  public setSearchItem(searchItem) {
    this.searchItems = searchItem
  }
}


@Injectable({
  providedIn: 'root'
})
export class NgstoreServiceChatWorkspace {
  public callingStatus$: Observable<CallingStatus>;
  public messagingStatus$: Observable<MessagingStatus>;
  // TODO: need to check the type
  public peerMessagesAndStatuses$: Observable<{
    [peerId: string]: {
      status: StateStatusLoadedSeq;
      messages: PeerChatMessage[];
      threadId: string;
      isMuted: boolean;
      peerId: string;
      participants: any;
      isGroup: boolean;
      messageChannelType: string;
    }
  }>;
  public loaded$: Observable<any>

  constructor(
    private readonly store: Store,
  ) {
    this.callingStatus$ = this.store.pipe(select(selectCallingStatus))
    this.messagingStatus$ = this.store.pipe(select(selectMessagingStatus))
    this.peerMessagesAndStatuses$ = this.store.select(selectPeerMessagesAndStatuses)
    this.loaded$ = this.store
      .select(selectMessagesStatus)
      .pipe(filter((f) => f.kind === 'StateStatusLoaded'));
  }

}