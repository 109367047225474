import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';



@Component({
    selector: 'movius-web-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent {
    getConnectionErrorValue: any;
    Version;
    date;
    clientVersion;
    constructor(
        private readonly modalService: NzModalService,
        private readonly cdr: ChangeDetectorRef
    ) {

    }



    version(){
        return localStorage.getItem('App-Version')
    }
}
