<!-- <div *ngIf="matchedMsgCount > 0"
    style="position: fixed; margin-top: 10px; border-radius: 5px; position: fixed; background-color: grey; color: white; padding: 5px; text-align: center; font-weight: bold;">
    {{matchedMsgCount}} Matches Found
</div>

<div *ngIf="matchedMsgCount > 0" style="margin-left: -35px;
    margin-top: 10px; border-radius: 5px;
    position: fixed;
    background-color: grey;
    color: white;
    padding: 5px; text-align: center; font-weight: bold;width: 26px;" (click)="goToNext()"> -->
<!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 407.437 407.437" style="enable-background:new 0 0 407.437 407.437;" xml:space="preserve">
<polygon points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 "/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
    </div>
    <div *ngIf="matchedMsgCount > 0" style="margin-left: 140px;
    margin-top: 10px; border-radius: 5px;
    position: fixed;
    background-color: grey;
    color: white;
    padding: 5px; text-align: center; font-weight: bold;width: 26px;" (click)="goToPrev()">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
<path id="XMLID_224_" d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394
   l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393
   C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
    </div> -->

    <div class="chat" [ngClass]="{'chat_padding':isMobileDevice, 'chat_padding':isDeleteactive}" *ngIf="session.messages.length > 0; else empty" #messagesContainer>

    <div *ngIf="loadedChat">
        <movius-web-spinner></movius-web-spinner>
    </div>
    <div *ngIf="messageLength > 20 && initialScroll" moviusWebChatScroll (appInfiniteScroll)="loadPreviousMessages()">
    </div>
    <movius-web-chat-item #item data-cy="message-chat-item"
        appLongPress
        (longPress)="onLongPress($event,msg)" (click)="handleMobileDelete($event,msg)"
        [cnt_id]="cnt_id" [peer_multiLine]="session.peer.multiLine"
        [isWhatsappOptinAccepted]="session.isWhatsOptInReqAccepted" [optInRequestCount]="session.optInRequestCount"
        [lastIncommingMessageAt]="session.lastIncommingMessageAt" [whatsOptInReqStatus]="session.whatsOptInReqStatus"
        *ngFor="let msg of allMessages$ | async; trackBy: trackByFun;" [message]="msg" [isSelf]="msg.from === 'me'"
        [sessionId]="session.threadId" [id]="msg.content" [isFirstNotRead]="msg.isFirstNotRead"
        (remove)="removeMessage.emit(msg)" (forward)="forwardMessage.emit(msg)" (copy)="copyMessage.emit(msg)"
        (scrollToBottom)="scrollToBottom('none')" (loadedImages)="loadedImages_chat($event)"
        [loadedPictureMessage_chat]="loadedPictureMessage_chat_workspace" [isMobileDevice]="isMobileDevice"
        [isLogingViaTeams]="isLogingViaTeams" [appEmbededStatus]=[appEmbededStatus] [GetTheme]="GetTheme"
        [isSelected]="selectedDeleteMessages.has(msg.id)"
        (deleteClick)="OndeleteThreadClick(msg,'deleteMenu')"
        (toggleDeleteSelected)="OndeleteThreadClick(msg,'selectMenu')"
        [deleteActive]="isDeleteactive"
        [isDeleteEnabled]="isDeleteEnabled"
    >
    </movius-web-chat-item>

</div>

<ng-template #empty class="chat_empty"></ng-template>
