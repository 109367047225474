<!-- <div class="error__generalFailure" [ngClass]="{
    'error__generalFailure--other-error': false
}" *ngIf="(serverConnectionError$ | async)">
    <div class="error__networkErrorIco">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
            <g fill="none" fill-rule="evenodd">
                <g fill="#FFF" fill-rule="nonzero">
                    <path
                        d="M14.06 13.64c.114.108.252.16.412.158.16-.002.294-.055.4-.158.11-.113.165-.246.165-.398 0-.153-.056-.284-.166-.392L1.937.166C1.822.058 1.685.002 1.527 0c-.158-.002-.292.053-.402.166C1.015.269.959.4.959.56c0 .16.056.292.166.395L14.059 13.64zm-6.058.422c.081 0 .165-.024.251-.073.086-.05.197-.138.33-.265l1.69-1.592c.057-.052.093-.117.107-.194.014-.078-.005-.152-.057-.222-.24-.306-.566-.564-.978-.775-.412-.212-.86-.317-1.343-.317-.494 0-.948.109-1.362.327-.415.219-.741.485-.981.8-.038.061-.05.126-.036.194.014.068.053.13.115.187l1.682 1.592c.138.127.25.215.334.265.084.049.166.073.248.073zM1.06 7.522c.072.076.158.112.259.11.1-.002.187-.043.258-.123.853-.879 1.83-1.546 2.932-2.002L2.921 3.943c-.57.296-1.097.628-1.58.997-.485.369-.906.767-1.265 1.195-.053.06-.078.133-.076.218.003.085.037.157.104.219l.956.95zm2.874 2.827c.077.08.166.117.266.112.1-.004.187-.049.259-.133.273-.301.593-.563.96-.786.366-.223.757-.4 1.174-.529.417-.129.843-.2 1.28-.215L5.99 6.952c-.657.183-1.257.443-1.8.779-.544.336-1 .715-1.37 1.138-.052.07-.077.145-.075.225.003.08.04.15.112.212l1.077 1.043zm8.89-.74l.323-.303c.062-.061.097-.132.104-.212.007-.08-.02-.155-.083-.225-.345-.404-.776-.768-1.293-1.092-.518-.325-1.083-.578-1.696-.762l2.644 2.594zm1.609-2.08c.067.071.15.107.248.107s.185-.04.262-.12l.949-.944c.067-.062.103-.134.107-.219.005-.085-.021-.157-.079-.218-.56-.658-1.255-1.245-2.083-1.762-.83-.517-1.743-.923-2.742-1.22-.999-.295-2.03-.443-3.093-.443-.345 0-.685.016-1.02.05-.336.032-.67.079-1 .14l1.99 1.952c.83-.005 1.627.095 2.394.3.766.204 1.49.507 2.173.909.683.401 1.314.891 1.894 1.469z"
                        transform="translate(-556 -68) translate(556 68)" />
                </g>
            </g>
        </svg>
    </div>
    <div class="error__generalFailureTxt">You are not connected to the server. Please click <span (click)="refresh()"
            class="">
            <svg width="16px" height="15px" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 20C9.76667 20 7.875 19.225 6.325 17.675C4.775 16.125 4 14.2333 4 12C4 9.76667 4.775 7.875 6.325 6.325C7.875 4.775 9.76667 4 12 4C13.15 4 14.25 4.23733 15.3 4.712C16.35 5.18667 17.25 5.866 18 6.75V4H20V11H13V9H17.2C16.6667 8.06667 15.9377 7.33333 15.013 6.8C14.0883 6.26667 13.084 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18C13.2833 18 14.4417 17.6333 15.475 16.9C16.5083 16.1667 17.2333 15.2 17.65 14H19.75C19.2833 15.7667 18.3333 17.2083 16.9 18.325C15.4667 19.4417 13.8333 20 12 20Z"
                    fill="white" />
            </svg>
    
        </span> to refresh the page</div>
    </div>

<div class="error__generalFailure" [ngClass]="{
    'error__generalFailure--other-error': false
}" *ngIf="!(onlineStatus$ | async)">
    <div class="error__networkErrorIco">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
            <g fill="none" fill-rule="evenodd">
                <g fill="#FFF" fill-rule="nonzero">
                    <path
                        d="M14.06 13.64c.114.108.252.16.412.158.16-.002.294-.055.4-.158.11-.113.165-.246.165-.398 0-.153-.056-.284-.166-.392L1.937.166C1.822.058 1.685.002 1.527 0c-.158-.002-.292.053-.402.166C1.015.269.959.4.959.56c0 .16.056.292.166.395L14.059 13.64zm-6.058.422c.081 0 .165-.024.251-.073.086-.05.197-.138.33-.265l1.69-1.592c.057-.052.093-.117.107-.194.014-.078-.005-.152-.057-.222-.24-.306-.566-.564-.978-.775-.412-.212-.86-.317-1.343-.317-.494 0-.948.109-1.362.327-.415.219-.741.485-.981.8-.038.061-.05.126-.036.194.014.068.053.13.115.187l1.682 1.592c.138.127.25.215.334.265.084.049.166.073.248.073zM1.06 7.522c.072.076.158.112.259.11.1-.002.187-.043.258-.123.853-.879 1.83-1.546 2.932-2.002L2.921 3.943c-.57.296-1.097.628-1.58.997-.485.369-.906.767-1.265 1.195-.053.06-.078.133-.076.218.003.085.037.157.104.219l.956.95zm2.874 2.827c.077.08.166.117.266.112.1-.004.187-.049.259-.133.273-.301.593-.563.96-.786.366-.223.757-.4 1.174-.529.417-.129.843-.2 1.28-.215L5.99 6.952c-.657.183-1.257.443-1.8.779-.544.336-1 .715-1.37 1.138-.052.07-.077.145-.075.225.003.08.04.15.112.212l1.077 1.043zm8.89-.74l.323-.303c.062-.061.097-.132.104-.212.007-.08-.02-.155-.083-.225-.345-.404-.776-.768-1.293-1.092-.518-.325-1.083-.578-1.696-.762l2.644 2.594zm1.609-2.08c.067.071.15.107.248.107s.185-.04.262-.12l.949-.944c.067-.062.103-.134.107-.219.005-.085-.021-.157-.079-.218-.56-.658-1.255-1.245-2.083-1.762-.83-.517-1.743-.923-2.742-1.22-.999-.295-2.03-.443-3.093-.443-.345 0-.685.016-1.02.05-.336.032-.67.079-1 .14l1.99 1.952c.83-.005 1.627.095 2.394.3.766.204 1.49.507 2.173.909.683.401 1.314.891 1.894 1.469z"
                        transform="translate(-556 -68) translate(556 68)" />
                </g>
            </g>
        </svg>
    </div>
    <div class="error__generalFailureTxt">You are not connected to the network</div>
</div> -->

<!-- <div class="error__generalFailure" [ngClass]="{
    'error__generalFailure--other-error': false
}" *ngIf="(slowConnection$ | async)">
    <div class="error__networkErrorIco">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 504.8 504.8" style="enable-background:new 0 0 504.8 504.8;" xml:space="preserve">
            <path style="fill:#BFDFE8;"
                d="M480,203.5c-6.4,0-12-2.4-16.8-7.2c-116.8-116-305.6-116-422.4,0c-9.6,9.6-24.8,9.6-33.6,0
        	c-9.6-9.6-9.6-24.8,0-33.6c135.2-135.2,355.2-135.2,490.4,0c9.6,9.6,9.6,24.8,0,33.6C492.8,201.1,486.4,203.5,480,203.5z" />
            <g>
                <path style="fill:#00A6F9;" d="M396,288.3c-6.4,0-12-2.4-16.8-7.2c-70.4-70.4-184-70.4-254.4,0c-9.6,9.6-24.8,9.6-33.6,0
        		c-9.6-9.6-9.6-24.8,0-33.6c88.8-88.8,232.8-88.8,321.6,0c9.6,9.6,9.6,24.8,0,33.6C408,285.9,402.4,288.3,396,288.3z" />
                <path style="fill:#00A6F9;" d="M309.6,369.1c-6.4,0-12-2.4-16.8-7.2c-22.4-22.4-59.2-22.4-81.6,0c-9.6,9.6-24.8,9.6-33.6,0
        		c-9.6-9.6-9.6-24.8,0-33.6c40.8-40.8,108-40.8,148.8,0c9.6,9.6,9.6,24.8,0,33.6C321.6,366.7,316,369.1,309.6,369.1z" />
                <circle style="fill:#00A6F9;" cx="252" cy="419.5" r="24" />
            </g>
        </svg>
    </div>
    <div class="error__generalFailureTxt">Your network connection is unstable</div>
</div> -->

<div class="error__generalFailure" [ngClass]="{
    'error__generalFailure--other-error': false
}" *ngIf="showBanner() == true">
    <div class="error__networkErrorIco">
        <!--  show icon -->
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
            <g fill="none" fill-rule="evenodd">
                <g fill="#FFF" fill-rule="nonzero">
                    <path
                        d="M14.06 13.64c.114.108.252.16.412.158.16-.002.294-.055.4-.158.11-.113.165-.246.165-.398 0-.153-.056-.284-.166-.392L1.937.166C1.822.058 1.685.002 1.527 0c-.158-.002-.292.053-.402.166C1.015.269.959.4.959.56c0 .16.056.292.166.395L14.059 13.64zm-6.058.422c.081 0 .165-.024.251-.073.086-.05.197-.138.33-.265l1.69-1.592c.057-.052.093-.117.107-.194.014-.078-.005-.152-.057-.222-.24-.306-.566-.564-.978-.775-.412-.212-.86-.317-1.343-.317-.494 0-.948.109-1.362.327-.415.219-.741.485-.981.8-.038.061-.05.126-.036.194.014.068.053.13.115.187l1.682 1.592c.138.127.25.215.334.265.084.049.166.073.248.073zM1.06 7.522c.072.076.158.112.259.11.1-.002.187-.043.258-.123.853-.879 1.83-1.546 2.932-2.002L2.921 3.943c-.57.296-1.097.628-1.58.997-.485.369-.906.767-1.265 1.195-.053.06-.078.133-.076.218.003.085.037.157.104.219l.956.95zm2.874 2.827c.077.08.166.117.266.112.1-.004.187-.049.259-.133.273-.301.593-.563.96-.786.366-.223.757-.4 1.174-.529.417-.129.843-.2 1.28-.215L5.99 6.952c-.657.183-1.257.443-1.8.779-.544.336-1 .715-1.37 1.138-.052.07-.077.145-.075.225.003.08.04.15.112.212l1.077 1.043zm8.89-.74l.323-.303c.062-.061.097-.132.104-.212.007-.08-.02-.155-.083-.225-.345-.404-.776-.768-1.293-1.092-.518-.325-1.083-.578-1.696-.762l2.644 2.594zm1.609-2.08c.067.071.15.107.248.107s.185-.04.262-.12l.949-.944c.067-.062.103-.134.107-.219.005-.085-.021-.157-.079-.218-.56-.658-1.255-1.245-2.083-1.762-.83-.517-1.743-.923-2.742-1.22-.999-.295-2.03-.443-3.093-.443-.345 0-.685.016-1.02.05-.336.032-.67.079-1 .14l1.99 1.952c.83-.005 1.627.095 2.394.3.766.204 1.49.507 2.173.909.683.401 1.314.891 1.894 1.469z"
                        transform="translate(-556 -68) translate(556 68)" />
                </g>
            </g>
        </svg>
    </div>
    <!-- show banner text -->

    <!-- for server connection error -->
    <div *ngIf="showBannerText('serverConnection') == true" class="error__generalFailureTxt">
        You are not connected to the server. Please click <span (click)="refresh()" class="">
            <svg width="16px" height="15px" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 20C9.76667 20 7.875 19.225 6.325 17.675C4.775 16.125 4 14.2333 4 12C4 9.76667 4.775 7.875 6.325 6.325C7.875 4.775 9.76667 4 12 4C13.15 4 14.25 4.23733 15.3 4.712C16.35 5.18667 17.25 5.866 18 6.75V4H20V11H13V9H17.2C16.6667 8.06667 15.9377 7.33333 15.013 6.8C14.0883 6.26667 13.084 6 12 6C10.3333 6 8.91667 6.58333 7.75 7.75C6.58333 8.91667 6 10.3333 6 12C6 13.6667 6.58333 15.0833 7.75 16.25C8.91667 17.4167 10.3333 18 12 18C13.2833 18 14.4417 17.6333 15.475 16.9C16.5083 16.1667 17.2333 15.2 17.65 14H19.75C19.2833 15.7667 18.3333 17.2083 16.9 18.325C15.4667 19.4417 13.8333 20 12 20Z"
                    fill="white" />
            </svg>
        </span> to refresh the page
    </div>

    <!-- for network error -->
    <div *ngIf="showBannerText('networkConnection') == true" class="error__generalFailureTxt">
        You are not connected to the network
    </div>

    <!-- show banner text end-->
</div>