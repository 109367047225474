import { IDBPDatabase } from 'idb';
import { MultiLineIdentifierUserLookup } from './models';
import { MoviusDbSchema } from './models/db-schema';

import { LoggerFactory } from '@movius/ts-logger';
const logger = LoggerFactory.getLogger('');

// Aggregator
export class MLIUserLookUpRepository {
    constructor(private readonly db: IDBPDatabase<MoviusDbSchema>) {}

    private get ctx() {
        const transaction = this.db.transaction(
            'multiLineIdentifierUserLookup',
            'readwrite'
        );
        const store = transaction.objectStore('multiLineIdentifierUserLookup');
        return { transaction, store };
    }
    async getAllMLIUserLookUp() {
        logger.debug("[MLI User lookup] Fetching data lookupTable")
        const {store, transaction} = this.ctx;
        const mliUserLookup = await store.getAll()
        logger.debug("[MLI User lookup] Fetching data lookupTable complete")
        return mliUserLookup
    }
    async getAllMLIUserLookUpKeys() {
        const {store, transaction} = this.ctx;
        let lookupNumbers =  await store.getAllKeys()
        return lookupNumbers
    }
    async getMLIUserLookup(number:string, transaction){
        // const {store,} = this.ctx;
        const mliLookup = await transaction.store.get(number)
        if(mliLookup){
            return mliLookup
        }
    }
    async addOrUpdateMLIUserLookup(lookups:MultiLineIdentifierUserLookup[]){
        logger.debug(`[MLI User lookup] Adding data to lookupTable. Items count ==> ${lookups?.length}`)
        const {store, transaction} = this.ctx;
        lookups.map(async lookup=>{
            if(lookup.number && lookup.number!=null && lookup.number != ""){
                const userlookup = await this.getMLIUserLookup(lookup.number, transaction)
                if(userlookup){
                    await transaction.store.put(lookup)
                }
                else{
                    await transaction.store.add(lookup)
                }
            }
        })
    }
}
