<div class="mat-app-background basic-container">
  <div class="mat-cont">
    <svg  class="align_item" *ngIf="isMobileDevice && GetTheme() == null" (click)="backToList()" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="80.593px" height="122.88px"
        viewBox="0 0 80.593 122.88" enable-background="new 0 0 80.593 122.88" xml:space="preserve">
        <g>
            <polygon points="80.593,0 49.771,0 0,61.44 49.771,122.88 80.593,122.88 30.82,61.44 80.593,0" />
        </g>
    </svg>
    <svg  class="align_item" *ngIf="isMobileDevice && GetTheme() == 'Dark'" (click)="backToList()" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="80.593px" height="122.88px"
        viewBox="0 0 80.593 122.88" fill="#eaeaea" xml:space="preserve">
        <g>
            <polygon points="80.593,0 49.771,0 0,61.44 49.771,122.88 80.593,122.88 30.82,61.44 80.593,0" />
        </g>
    </svg>
    <span class="to-header" [ngStyle]="{'margin': isMobileDevice ? '3px 0px 0px 16px' : '3px 0px 0px 0px' }">To:</span>
    <mat-form-field class="example-chip-list" floatLabel="never">
      <mat-chip-list #chipList class="mat-chip-list" [ngClass]="isMobileDevice ? 'mat-chip-list-mobile': 'mat-chip-list'" >
        <mat-chip class="profile-card" *ngFor="let contact of userContactsI;let i= index" [selectable]="selectable"
          [removable]="removable" (removed)="removeList($event,i)" (keydown)="removeList($event,i)">
          <movius-web-contact-logo customLogo class="profile-iconsavatar" [peer]="getPeer(contact.ContactNumber, undefined, contact.ContactName)"
            alt="Peer Logo" [doZoomLetters]="true" [mlIdentifierData]="getMLIdentifierData(contact)"></movius-web-contact-logo>
          <p class="title-name-number">{{getContactTitle(contact)}}</p>
          <svg *ngIf="removable" (click)="removeContact($event,i)" class="card-iconsallclose"
            xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g fill="none" fill-rule="evenodd">
              <g fill="gray">
                <path
                  d="M11.7 1.77c.399-.398.398-1.042.002-1.438l-.034-.034C11.271-.099 10.628-.1 10.23.3L6.19 4.34c-.104.104-.275.103-.377 0L1.77.3C1.372-.1.728-.099.33.297L.299.332C-.099.729-.1 1.372.3 1.77L4.34 5.81c.104.104.103.275 0 .377L.3 10.23c-.399.399-.398 1.043-.002 1.44l.034.033c.397.397 1.04.398 1.439-.001L5.81 7.66c.104-.104.275-.103.377 0l4.041 4.04c.399.399 1.043.398 1.44.002l.033-.034c.397-.397.398-1.04-.001-1.439L7.66 6.19c-.104-.104-.103-.275 0-.377L11.7 1.77z"
                  transform="translate(-1236 -95) translate(1236 95)" />
              </g>
            </g>
          </svg>
        </mat-chip>
        <input [ngClass]="isMobileDevice ? 'input-mobile': 'input'" matInput type="text" maxlength="16"
          [placeholder]="placeHolderMessageText()"
          #contactInput #autocompleteTrigger="matAutocompleteTrigger" [formControl]="contactCtrl"
          [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addContact($event)" (click)="closeAutocompleteOn()"
          (mousedown)="closeAutocompleteOn()" (keyup)="closeAutocompleteOnKeyUp()">
      </mat-chip-list>
      <div *ngIf="participantCount > 1" class="action_lft">
        <p class="participants-count">Participants {{participantCount}}/{{participantLimit - 1}}</p>
      </div>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedContact($event)" autoActiveFirstOption>
        <ng-container *ngIf="showMatOptions">
          <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
            <span class="drop-down-temp">
              <communication-item [peer]="contact" alt="Peer Logo" [doZoomLetters]="true" [mlIdentifierData]="getMLIdentifierData(contact)">
                <highlighted-text class="cntName" [fullText]="contact.ContactName?.substring(0, contact.ContactName?.lastIndexOf('_')) +' '" [toFind]="contactCtrl.value" title></highlighted-text>
                <highlighted-text class="cntPhone" [fullText]="contact.ContactNumber" [toFind]="contactCtrl.value" title></highlighted-text>
              </communication-item>
            </span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
