<movius-web-shared-details-workspace
    *ngIf="view$ | async as view"
    [getActiveChatId]="getActiveChatId"
    [userGhost]="view.ghost"
    [hasWeChatThread]="hasWeChatThread"
    [hasLineThread]="hasLineThread"
    [lineThreads]="lineThreads"
    [wechatThreads]="wechatThreads"
    [doShowAddToExisting]="(peers$ | async)?.length > 0"
    (onCreateContactOccured)="onCreateContact($event)"
    (onAddToContactOccured)="onAddToContact($event)"
    (onDeleteContactOccured) = "onDeleteContact($event)"
    (onWhatsAppMessageOccured) = "onWhatsAppMessage($event)"
    (onLineMessageOccured) = "onLineMessage($event)"
    (onWeChatMessageOccured) = "onWeChatMessage($event)"
    (onMessageClick)="disabelVoiceMailFilter()"
>
</movius-web-shared-details-workspace>
